export default class HtmlBlock {
  constructor({ data, config, api }) {
    this.api = api
    this.saveData = this.save.bind(this)
    this.options = config.options
    this.data = data
  }

  render() {
    const wrapper = document.createElement('div')
    wrapper.classList.add('editorjs-select__wrapper')
    this.select = document.createElement('select')
    this.select.classList.add('editorjs-select')
    this.options.forEach((el) => {
      const option = document.createElement('option')
      option.value = el.id
      option.innerHTML = el.name
      this.select.appendChild(option)
    })
    this.select.value =
      this.data && this.data.blockId ? this.data.blockId : null
    wrapper.appendChild(this.select)
    return wrapper
  }

  save() {
    return {
      blockId: this.select.value
    }
  }

  static get toolbox() {
    return {
      icon: '<span>HTML</span>',
      title: 'HTML block'
    }
  }
}
