import React from 'react'
import { Spin } from 'antd'
import { css } from 'linaria'
import { fm } from '../lang'

const loadingContainer = css`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LoadingScreen = () => {
  return (
    <div className={loadingContainer}>
      <Spin size="large" tip={fm('spin.connecting')} />
    </div>
  )
}
