import React from 'react'
import { Card, Row, Col, Form, Select, Input } from 'antd'
import { fm } from '../../../../lang'
import { onKeyDownForm } from '../../../../helpers/form'
import { useSelector } from 'react-redux'
import { FormActionBox } from '../../../Shared/FormActionBox'
import { useRecordHook } from '../../../../helpers/hooks/useRecordHook'
import { getBlocksTags } from '../../../../data/selectors/blocks'
import { TranslationTabs } from '../../../Shared/Translations/TranslationTabs'
import { emptyTranslation } from '../../../../data/reducers/blocksReducer'

const { Option } = Select

export const ProductsCompare = ({ form, onFinish, onDelete, isLoading }) => {
  const products = useSelector((state) => state.products.entries)
  const groups = useSelector((state) => state.specificationGroups.entries)
  const blockTags = useSelector(getBlocksTags)
  const tags = []
  blockTags.forEach((tag) => {
    tags.push(<Option key={tag}>{tag}</Option>)
  })
  const { newRecord } = useRecordHook()
  return (
    <Row>
      <Col xs={24}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          onKeyDown={onKeyDownForm}
        >
          <Form.Item
            name="name"
            label={fm('label.name')}
            rules={[
              {
                required: true,
                message: 'Please input name'
              }
            ]}
          >
            <Input style={{ width: 400 }} />
          </Form.Item>
          <Form.Item name="tags" label={fm('label.tags')}>
            <Select mode="tags" style={{ width: 400 }}>
              {tags}
            </Select>
          </Form.Item>
          <Card>
            <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
              {({ namespace, translation }) =>
                <>
                  <Form.Item
                    label={'Compare title'}
                    name={[namespace, 'title']}
                  >
                    <Input />
                  </Form.Item>
                </>
              }
            </TranslationTabs>
          </Card>
          <Form.Item
            label={fm('label.products')}
            name={['properties', 'products']}
          >
            <Select
              placeholder="Please select products"
              style={{ width: 300 }}
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              {products.map((p) => (
                <Select.Option key={p.id} value={p.id}>
                  {p.translations.find(({ locale }) => locale === 'en').name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'Specification groups'}
            name={['properties', 'specificationGroups']}
          >
            <Select
              placeholder="Please select a group"
              style={{ width: 300 }}
              mode={'multiple'}
            >
              {groups.map((l) => (
                <Select.Option key={l.id} value={l.id}>
                  {l.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <FormActionBox
            isLoading={isLoading}
            newRecord={newRecord}
            onDelete={onDelete}
          />
        </Form>
      </Col>
    </Row>
  )
}
