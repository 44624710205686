import React from 'react'
import { Card, Table } from 'antd'
// import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { fm } from '../../../lang'
// import { usePush } from '../../../data/actions/routes'
import { getProductColors } from '../../../data/selectors/productColors'

const columns = [
  {
    title: fm('table.title'),
    key: 'title',
    render: (_, { translations }) => (
      translations.find(({ locale }) => locale === 'en').title
    )
  },
  {
    title: fm('table.description'),
    key: 'description',
    render: (_, { translations }) => (
      translations.find(({ locale }) => locale === 'en').description
    )
  },
  {
    title: fm('table.slug'),
    key: 'slug',
    render: (_, { translations }) => (
      translations.find(({ locale }) => locale === 'en').slug
    )
  }
]

export const ProductColorsTable = ({ isLoading }) => {
  // const push = usePush()
  // const dispatch = useDispatch()
  const { id: productId } = useParams()
  const productColors = useSelector((state) => getProductColors(state, productId))
  // const handleClickAdd = () => {
  //   push('/settings/shops/new')
  // }
  // const handleClickRow = useCallback(
  //   (recordId) => {
  //     push(`/assortment/products/${productId}/colors/${recordId}`)
  //   },
  //   [push, dispatch]
  // )
  return (
    <Card size={'small'}>
      <Table
        // onRow={(record, rowIndex) => ({
        //   onClick: () => handleClickRow(record.id)
        // })}
        dataSource={productColors}
        columns={columns}
        size="small"
        loading={isLoading}
        rowKey={'id'}
      />
    </Card>
  )
}
