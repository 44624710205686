import * as types from '../../constants/actionTypes/productsActionTypes'

export const createProduct = (dispatch, params) => {
  return dispatch.sync({
    type: types.PRODUCTS_CREATE,
    product: params
  })
}

export const updateProduct = (dispatch, params) => {
  return dispatch.sync({
    type: types.PRODUCTS_UPDATE,
    product: params
  })
}

export const deleteProduct = (dispatch, id, push) => {
  push('/assortment/products')
  dispatch.sync({
    type: types.PRODUCTS_DELETE,
    product: { id }
  })
}

export const selectProduct = (dispatch, id) => {
  dispatch.local({
    type: types.PRODUCT_SELECT,
    id
  })
}

export const resetProduct = (dispatch, id) => {
  dispatch.local({
    type: types.PRODUCT_FORM_RESET,
    id
  })
}

export const removeProductMedia = (dispatch, id, mediaId) => {
  dispatch.local({
    type: types.PRODUCT_REMOVE_MEDIA,
    id,
    mediaId
  })
}

export const changeProductsTableFilters = (
  dispatch,
  filterType,
  filterValue
) => {
  dispatch.local({
    type: types.PRODUCTS_CHANGE_TABLE_FILTER,
    filterType,
    filterValue
  })
}
