import React from 'react'
import { Tree } from 'antd'
import { useSelector } from 'react-redux'

export const PermissionsTree = ({ values, setValues, readOnly }) => {
  const { entries } = useSelector((state) => state.permissions)

  const onCheck = (checkedKeys) => {
    setValues(checkedKeys)
  }

  return (
    <div>
      <Tree
        checkable
        onCheck={onCheck}
        treeData={entries}
        checkedKeys={values}
        disabled={readOnly}
      />
    </div>
  )
}
