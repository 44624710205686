import React from 'react'
import { Form, Input, Select } from 'antd'
import { useSelector } from 'react-redux'

export const MediaGroupFields = ({ namespace = [], fieldName = 'mediaGroups' }) => {
  const mediaGroups = useSelector((state) => state.mediaGroups.entries)

  return (
    <Form.Item shouldUpdate>
      {(f) => {
        const medias = f.getFieldValue('medias')
        return (
          medias && (
            <Form.List name={[...namespace, 'mediaGroups']}>
              {(fields) => (
                fields.map((field) => {
                  const mediaGroup = f.getFieldValue('mediaGroups')[field.key]
                  const group = mediaGroups.find((el) => el.id === mediaGroup.groupId)
                  return (
                    <Form.Item key={field.key}>
                      <Form.Item
                        name={[field.key, 'groupId']}
                        hidden={true}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={group.name}
                        name={[field.key, group.collection ? 'mediaIds' : 'mediaId']}
                      >
                        <Select
                          size={'large'}
                          allowClear
                          mode={group.collection ? 'multiple' : null}
                        >
                          {medias.filter((el) => el.parentId !== null).map((el) => (
                            <Select.Option
                              key={el.parentId}
                              value={el.parentId}
                            >
                              <img
                                src={el.thumbUrl}
                                width={38}
                                height={38}
                              />
                              <span>{el.name}</span>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Form.Item>
                  )
                })
              )}
            </Form.List>
          )
        )
      }}
    </Form.Item>
  )
}
