export default [
  {
    value: 'usd',
    label: 'USD($)'
  },
  {
    value: 'eur',
    label: 'EUR(€)'
  },
  {
    value: 'gbp',
    label: 'GBP(£)'
  }
]
