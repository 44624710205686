import React from 'react'
import { css } from 'linaria'
import logoSrc from '../../../assets/logo.png'

const logo = css`
  width: 120px;
`

export const Logo = () => {
  return <img src={logoSrc} className={logo} />
}
