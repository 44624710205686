import React, { useEffect, useState } from 'react'
import { Table, Card, Row, Col, Button } from 'antd'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import { arrayMoveImmutable } from 'array-move'
import { useSelector } from 'react-redux'
import { ModalLinkForm } from './ModalLinkForm'

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

const emptyTranslation = {
  locale: null,
  title: ''
}
const emptyLink = {
  categoryId: null,
  id: null,
  linkType: 'category',
  position: 'top',
  hoverImageId: '',
  textContent: [{ ...emptyTranslation, locale: 'en' }],
  url: ''
}

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)
export const LinksTable = ({
  links,
  saveLink,
  updateLinks,
  removeLink,
  files
}) => {
  const categories = useSelector((state) => state.categories.entries)
  const [link, setLink] = useState(emptyLink)
  const [modalOpen, setModalOpen] = useState(false)
  const [dataSource, setDataSource] = useState(links)
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      dataIndex: 'linkType',
      width: 200,
      className: 'drag-visible',
      render: (_, record) => {
        return record.linkType === 'category'
          ? (
          <span>
            {categories.find((el) => el.id === record.categoryId)
              ? categories.find((el) => el.id === record.categoryId).name
              : 'Category not found'}
          </span>
            )
          : (
          <span>
            {record.textContent[0].title} - {record.url}
          </span>
            )
      }
    },
    {
      title: 'Position',
      dataIndex: 'position',
      width: 100,
      className: 'drag-visible'
    },
    {
      title: 'Actions',
      width: 100,
      className: 'drag-hidden',
      render: (record) => {
        return (
          <>
            <Button
              size={'small'}
              type={'ghost'}
              onClick={() => editLink(record.id)}
            >
              Edit
            </Button>
            <Button
              style={{ marginLeft: 16 }}
              size={'small'}
              type={'danger'}
              onClick={() => remove(record.id)}
            >
              Remove
            </Button>
          </>
        )
      }
    }
  ]
  useEffect(() => {
    setDataSource(links)
  }, [links])
  const editLink = (id) => {
    setLink({ ...emptyLink, ...links.find((el) => el.id === id) })
    setModalOpen(true)
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource, oldIndex, newIndex)
        .filter((el) => !!el)
        .map((el, index) => {
          return { ...el, index }
        })
      setDataSource(newData)
      updateLinks(newData)
    }
  }

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.id === restProps['data-row-key']
    )
    return <SortableItem index={index} {...restProps} />
  }

  const addNewLink = () => {
    setLink(emptyLink)
    setModalOpen(true)
  }

  const remove = (id) => {
    setDataSource(dataSource.filter((el) => el.id !== id))
    removeLink(id)
  }

  return (
    <Card
      size={'small'}
      title={'Links'}
      extra={
        <Button type="primary" onClick={addNewLink}>
          Add a link
        </Button>
      }
    >
      <Row gutter={[16, 16]}>
        <ModalLinkForm
          saveLink={saveLink}
          link={link}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          files={files}
          emptyTranslation={emptyTranslation}
        />
        <Col xs={24}>
          <Table
            size={'small'}
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }}
          />
        </Col>
      </Row>
    </Card>
  )
}
