export const PAGES_UPDATE = 'pages/update'
export const PAGES_CREATE = 'pages/create'
export const PAGES_DELETE = 'pages/delete'
export const PAGE_FETCH = 'page/fetch'
export const PAGES_FETCH = 'pages/fetch'
export const PAGE_SELECT = 'page/select'
export const PAGE_FORM_RESET = 'page/form-reset'
export const PAGE_ADD_BLOCK = 'page/add_block'
export const PAGE_REMOVE_BLOCK = 'page/remove_block'
export const PAGES_AFTER_DESTROY_BLOCK = 'pages/after_destroy_block'
export const PAGES_CHANGE_TABLE_FILTER = 'pages/change_table_filter'
export const PAGES_FETCH_TRANSLATE = 'pages/fetch_translate'
export const PAGES_FETCH_TRANSLATE_SUCCESS = 'pages/fetch_translate_success'
export const PAGES_UPDATE_LOCAL = 'pages/update_local'
