import React from 'react'
import { Form, Input } from 'antd'

import { fm } from '../../../lang'

export const TranslationFields = ({ namespace, locale }) => {
  return (
    <>
      <Form.Item
        label={fm('label.translations.name')}
        name={[namespace, 'name']}
        required={locale === 'en'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.slug')}
        name={[namespace, 'slug']}
        required={locale === 'en'}
      >
        <Input />
      </Form.Item>
    </>
  )
}
