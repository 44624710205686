import * as types from '../../constants/actionTypes/usersActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyUser = {
  id: null,
  email: '',
  firstName: '',
  lastName: '',
  password: null,
  confirm: null,
  newRecord: true
}

const defaultState = {
  entry: { ...emptyUser, id: uuidv4() },
  entries: [],
  availableRoles: []
}

export default function usersReducer(state = defaultState, action) {
  switch (action.type) {
    case types.USERS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.user.id) {
            return { ...action.user, pasword: null, confirm: null }
          } else {
            return u
          }
        })
      }
    case types.USERS_DELETE:
      return {
        ...state,
        entry: { ...emptyUser, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.user.id)
      }
    case types.USER_FETCH:
      return { ...state, entry: action.data }
    case types.USERS_CREATE:
      return {
        ...state,
        entries: [
          { ...action.user, password: null, confirm: null },
          ...state.entries
        ]
      }
    case types.USER_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.USER_FORM_RESET:
      return { ...state, entry: { ...emptyUser, id: uuidv4() } }
    case types.USERS_FETCH:
      return {
        ...state,
        entries: action.data.users,
        availableRoles: action.data.roles
      }
    default:
      return state
  }
}
