const buttonIcon =
  '<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">\n' +
  '    <path d="M3.15 13.628A7.749 7.749 0 0 0 10 17.75a7.74 7.74 0 0 0 6.305-3.242l-2.387-2.127-2.765 2.244-4.389-4.496-3.614 3.5zm-.787-2.303l4.446-4.371 4.52 4.63 2.534-2.057 3.533 2.797c.23-.734.354-1.514.354-2.324a7.75 7.75 0 1 0-15.387 1.325zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z"/>\n' +
  '</svg>'
/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class Ui {
  /**
   * @param {object} ui - image tool Ui module
   * @param {object} ui.api - Editor.js API
   * @param {ImageConfig} ui.config - user config
   * @param {Function} ui.onSelectFile - callback for clicks on Select file button
   * @param {boolean} ui.readOnly - read-only mode flag
   */
  constructor({ api, config, onSelectFile, readOnly }) {
    this.api = api
    this.config = config
    this.onSelectFile = onSelectFile
    this.readOnly = readOnly
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper]),
      inner: make('div', ['product-about-category__inner']),
      innerFact: make('div', ['product-about-category__inner-fact']),
      innerText: make('div', ['product-about-category__inner-text']),
      imageContainer: make('div', [this.CSS.imageContainer]),
      fileButton: this.createFileButton(),
      imageEl: undefined,
      imagePreloader: make('div', this.CSS.imagePreloader),
      description: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: !this.readOnly
      }),
      title: make('input', [this.CSS.input]),
      factTextFirst: make('input', [this.CSS.input]),
      factTextSecond: make('input', [this.CSS.input]),
      factCaptionFirst: make('input', [this.CSS.input]),
      factCaptionSecond: make('input', [this.CSS.input])
    }
    this.nodes.description.dataset.placeholder = this.config.descriptionPlaceholder
    this.nodes.title.placeholder = this.config.titlePlaceholder
    this.nodes.factTextFirst.placeholder = this.config.factTextFirstPlaceholder
    this.nodes.factTextSecond.placeholder = this.config.factTextSecondPlaceholder
    this.nodes.factCaptionFirst.placeholder = this.config.factCaptionFirstPlaceholder
    this.nodes.factCaptionSecond.placeholder = this.config.factCaptionSecondPlaceholder
    this.nodes.innerText.appendChild(this.nodes.title)
    this.nodes.innerText.appendChild(this.nodes.description)
    this.nodes.imageContainer.appendChild(this.nodes.imagePreloader)
    this.nodes.innerFact.appendChild(this.nodes.imageContainer)
    this.nodes.innerFact.appendChild(this.nodes.fileButton)
    this.nodes.innerFact.appendChild(this.nodes.factTextFirst)
    this.nodes.innerFact.appendChild(this.nodes.factCaptionFirst)
    this.nodes.innerFact.appendChild(this.nodes.factTextSecond)
    this.nodes.innerFact.appendChild(this.nodes.factCaptionSecond)
    this.nodes.inner.appendChild(this.nodes.innerText)
    this.nodes.inner.appendChild(this.nodes.innerFact)
    this.nodes.wrapper.appendChild(this.nodes.inner)
  }

  /**
   * CSS classes
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'product-about-category',
      imageContainer: 'product-about-category__image',
      imagePreloader: 'product-about-category__image-preloader',
      imageEl: 'product-about-category__image-picture',
      caption: 'product-about-category__caption'
    }
  }

  /**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   *
   * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
   */
  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled'
    }
  }

  /**
   * Renders tool UI
   *
   * @param {ImageToolData} toolData - saved tool data
   * @returns {Element}
   */
  render(toolData) {
    if (!toolData.file || Object.keys(toolData.file).length === 0) {
      this.toggleStatus(Ui.status.EMPTY)
    } else {
      this.toggleStatus(Ui.status.UPLOADING)
    }

    return this.nodes.wrapper
  }

  /**
   * Creates upload-file button
   *
   * @returns {Element}
   */
  createFileButton() {
    const button = make('div', [this.CSS.button])

    button.innerHTML =
      this.config.buttonContent ||
      `${buttonIcon} ${this.api.i18n.t('Select an Image')}`

    button.addEventListener('click', () => {
      this.onSelectFile()
    })

    return button
  }

  /**
   * Shows uploading preloader
   *
   * @param {string} src - preview source
   * @returns {void}
   */
  showPreloader(src) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`

    this.toggleStatus(Ui.status.UPLOADING)
  }

  /**
   * Hide uploading preloader
   *
   * @returns {void}
   */
  hidePreloader() {
    this.nodes.imagePreloader.style.backgroundImage = ''
    this.toggleStatus(Ui.status.EMPTY)
  }

  /**
   * Shows an image
   *
   * @param {string} url - image source
   * @returns {void}
   */
  fillImage(url) {
    /**
     * Check for a source extension to compose element correctly: video tag for mp4, img — for others
     */
    const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG'

    const attributes = {
      src: url
    }

    /**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
    let eventName = 'load'

    /**
     * Update attributes and eventName if source is a mp4 video
     */
    if (tag === 'VIDEO') {
      /**
       * Add attributes for playing muted mp4 as a gif
       *
       * @type {boolean}
       */
      attributes.autoplay = true
      attributes.loop = true
      attributes.muted = true
      attributes.playsinline = true

      /**
       * Change event to be listened
       *
       * @type {string}
       */
      eventName = 'loadeddata'
    }

    /**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
    this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes)

    /**
     * Add load event listener
     */
    this.nodes.imageEl.addEventListener(eventName, () => {
      this.toggleStatus(Ui.status.FILLED)

      /**
       * Preloader does not exists on first rendering with presaved data
       */
      if (this.nodes.imagePreloader) {
        this.nodes.imagePreloader.style.backgroundImage = ''
      }
    })

    this.nodes.imageContainer.appendChild(this.nodes.imageEl)
  }

  /**
   * Shows caption input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillCaption(text) {
    if (this.nodes.description) {
      this.nodes.description.innerHTML = text
    }
  }

  fillInput(nodeName, value) {
    if (this.nodes[nodeName]) {
      this.nodes[nodeName].value = value
    }
  }

  toggleStatus(status) {
    for (const statusType in Ui.status) {
      if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
        this.nodes.wrapper.classList.toggle(
          `${this.CSS.wrapper}--${Ui.status[statusType]}`,
          status === Ui.status[statusType]
        )
      }
    }
  }

  applyTune(tuneName, status) {
    this.nodes.wrapper.classList.toggle(
      `${this.CSS.wrapper}--${tuneName}`,
      status
    )
  }
}

export const make = function make(tagName, classNames = null, attributes = {}) {
  const el = document.createElement(tagName)

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames)
  } else if (classNames) {
    el.classList.add(classNames)
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName]
  }

  return el
}
