import React from 'react'

import AceEditor from 'react-ace'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-scss'
import 'ace-builds/src-noconflict/ext-language_tools'

export const CodeInput = ({ mode, name, refAce, defaultValue, onChange, height }) => {
  return (
    <AceEditor
      ref={refAce}
      mode={mode}
      theme="monokai"
      name={name}
      defaultValue={defaultValue}
      onChange={onChange}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      width='100%'
      height={height}
      enableBasicAutocompletion={true}
      enableLiveAutocompletion={true}
      showLineNumbers={true}
      tabSize={2}
      setOptions={{
        animatedScroll: true,
        scrollPastEnd: 0.1
      }}
    />
  )
}
