import React from 'react'
import { useSelector } from 'react-redux'
import { Layout } from 'antd'
import { css } from 'linaria'

import { MainSidebar } from '../components/Shared/MainSidebar'
import { Logo } from '../components/Shared/header/Logo'
import { UserProfile } from '../components/Shared/header/UserProfile'
import { DashboardRoute } from '../routes/DashboardRoute'
import { SharedRoute } from '../routes/SharedRoute'
import { UsersRoute } from '../routes/Settings/UsersRoute'
import { RolesRoute } from '../routes/Settings/RolesRoutes'
import { useSubscription } from '@logux/redux'
import { useAllow } from '../helpers/hooks/usePermissions'
import { CustomersRoute } from '../routes/CustomersRoute'
import { BlocksRouter } from '../routes/Content/BlocksRoute'
import { ShopsRoute } from '../routes/Settings/ShopsRoute'
import { WebsitesRoute } from '../routes/Content/WebsitesRoute'
import { RedirectsRoute } from '../routes/Content/RedirectsRoute'
import { PagesRoute } from '../routes/Content/PagesRoute'
import { LoadingScreen } from './LoadingScreen'
import { ProductsRoute } from '../routes/Products/ProductsRoute'
import { OrdersRoute } from '../routes/Orders/OrdersRoute'
import { CategoriesRoute } from '../routes/Categories/CategoriesRoute'
import { DiscountsRoute } from '../routes/Discounts/DiscountsRoute'
import { VouchersRoute } from '../routes/Vouchers/VouchersRoute'
import { FreeFormsRoute } from '../routes/FreeForms/FreeFormsRoute'
import { SpecificationGroupsRoute } from '../routes/Assortment/SpecificationGroupsRoute'
import { SpecificationsRoute } from '../routes/Assortment/SpecificationsRoute'
import { ArticlesRouter } from '../routes/Content/ArticlesRoute'
import { TagsRouter } from '../routes/Content/TagsRoute'

const { Content, Header } = Layout

const mainLayout = css`
  height: 100vh;
`

const header = css`
  padding-right: 20px;
  padding-left: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000000;
`

const contentWrapper = css`
  background: #ffffff;
  overflow: auto;
  height: calc(100% - 22px);
`

export const LayoutScreen = () => {
  const allow = useAllow()
  const { isAuthenticated, userData } = useSelector((state) => state.auth)
  const { userId } = userData
  const isLoading = useSubscription([`account/${userId}`])
  return isLoading
    ? (
    <LoadingScreen />
      )
    : (
    <Layout className={mainLayout}>
      <Header className={header}>
        <Logo />
        <UserProfile />
      </Header>
      <Layout className={contentWrapper}>
        <MainSidebar />
        <Content className={contentWrapper}>
          <Layout className={contentWrapper}>
            <SharedRoute isAuthenticated={isAuthenticated} />
            {allow('can_show_dashboard') && (
              <DashboardRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_articles') && (
              <ArticlesRouter isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_tags') && (
              <TagsRouter isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_blocks') && (
              <BlocksRouter isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_customers') && (
              <CustomersRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_shops') && (
              <ShopsRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_users') && (
              <UsersRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_roles') && (
              <RolesRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_orders') && (
              <OrdersRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_websites') && (
              <WebsitesRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_pages') && (
              <PagesRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_redirects') && (
                <RedirectsRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_products') && (
              <ProductsRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_categories') && (
              <CategoriesRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_discounts') && (
              <DiscountsRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_vouchers') && (
              <VouchersRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_free_forms') && (
              <FreeFormsRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_specifications') && (
              <SpecificationsRoute isAuthenticated={isAuthenticated} />
            )}
            {allow('can_show_specifications') && (
              <SpecificationGroupsRoute isAuthenticated={isAuthenticated} />
            )}
          </Layout>
        </Content>
      </Layout>
    </Layout>
      )
}
