import React, { useCallback } from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { fm } from '../../lang'
import { usePush } from '../../data/actions/routes'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { VouchersTableHeader } from './VouchersTableHeader'
import { getFilteredVouchers } from '../../data/selectors/vouchers'

const columns = [
  {
    title: fm('vouchers.table.name'),
    dataIndex: 'name'
  },
  {
    title: fm('vouchers.table.code'),
    dataIndex: 'code'
  },
  {
    title: fm('table.start-at'),
    dataIndex: 'startAt',
    render: (startAt) => moment(startAt).format('MMMM Do YYYY')
  },
  {
    title: fm('table.end-at'),
    dataIndex: 'endAt',
    render: (endAt) => moment(endAt).format('MMMM Do YYYY')
  },
  {
    title: fm('vouchers.table.discount-type'),
    dataIndex: 'discountType'
  },
  {
    title: fm('vouchers.table.usages'),
    dataIndex: 'usages',
    render: (_, record) => (
      record.maxUsages ? `${record.usages}/${record.maxUsages}` : record.usages
    )
  },
  {
    title: fm('table.created-at'),
    dataIndex: 'createdAt',
    render: (createdAt) => moment(createdAt).format('MMMM Do YYYY')
  }
]

export const VouchersTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const dispatch = useDispatch()
  const entries = useSelector(getFilteredVouchers)
  const { giftVoucherUsages } = useSelector(state => state.vouchers)
  const handleClickAdd = () => {
    push('/vouchers/new')
  }
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/vouchers/${recordId}`)
    },
    [push, dispatch]
  )
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('vouchers.title')}
      extra={
        allow('can_create_voucher') && (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size={'small'}>
        <VouchersTableHeader />
      </Card>
      <Card size={'small'}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey={'id'}
          footer={() => `Gift voucher usages: ${giftVoucherUsages}`}
        />
      </Card>
    </PageHeader>
  )
}
