export const BLOCKS_UPDATE = 'blocks/update'
export const BLOCKS_UPDATE_LOCAL = 'blocks/update-local'
export const BLOCKS_CREATE = 'blocks/create'
export const BLOCKS_DELETE = 'blocks/delete'
export const BLOCK_FETCH = 'block/fetch'
export const BLOCKS_FETCH = 'blocks/fetch'
export const BLOCK_SELECT = 'block/select'
export const BLOCK_FORM_RESET = 'block/form-reset'
export const BLOCK_CREATED = 'block/created'
export const BLOCK_UPDATED = 'block/updated'
export const BLOCK_REMOVE_FILE = 'block/remove-file'
export const BLOCKS_CHANGE_TABLE_FILTER = 'blocks/change_table_filter'
export const BLOCKS_CLEAR_TABLE_FILTERS = 'blocks/clear_table_filters'
