import React from 'react'
import { Row, Col, Statistic, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { sendOrderEmail } from '../../../data/actions/orders'
import { TranslationOutlined } from '@ant-design/icons'

export const EmailStats = () => {
  const order = useSelector((state) => state.orders.entry)
  const { sendingEmail } = useSelector((state) => state.orders.requests)
  const {
    emailStatsConfirmation
  } = order
  const dispatch = useDispatch()
  const send = (emailType) => {
    sendOrderEmail(dispatch, order.id, emailType)
  }
  return (
    <Row>
      <Col span={6}>
        <Statistic
          title="Confirmation"
          value={emailStatsConfirmation ? 'Yes' : 'No'}
        />
        <Button
          style={{ marginTop: 16 }}
          type="primary"
          onClick={() => send('confirmation')}
          icon={<TranslationOutlined />}
          loading={sendingEmail}
        >
          Send again
        </Button>
      </Col>
    </Row>
  )
}
