import React from 'react'
import { Card, Row, Col, Form, Select, Input } from 'antd'
import { onKeyDownForm } from '../../../../helpers/form'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { FormActionBox } from '../../../Shared/FormActionBox'
import { useRecordHook } from '../../../../helpers/hooks/useRecordHook'
import { getBlocksTags } from '../../../../data/selectors/blocks'
import { ComponentsTable } from './ComponentsTable'
import { fm } from '../../../../lang'
import { TranslationTabs } from '../../../Shared/Translations/TranslationTabs'
import { emptyTranslation } from '../../../../data/reducers/blocksReducer'

const { Option } = Select

export const CollectionBlock = ({ form, onFinish, onDelete, isLoading }) => {
  const blockTags = useSelector(getBlocksTags)
  const tags = []
  blockTags.forEach((tag) => {
    tags.push(<Option key={tag}>{tag}</Option>)
  })
  const { newRecord } = useRecordHook()
  const saveComponent = (component) => {
    const oldProperties = form.getFieldValue('properties')
    let newProperties = []
    if (component.id) {
      newProperties = {
        ...oldProperties,
        components: oldProperties.components.map((el) => {
          if (el.id === component.id) {
            return component
          } else {
            return el
          }
        })
      }
    } else {
      newProperties = {
        ...oldProperties,
        components: oldProperties.components
          ? oldProperties.components.concat({ ...component, id: uuidv4() })
          : [{ ...component, id: uuidv4() }]
      }
    }
    form.setFieldsValue({ properties: newProperties })
  }
  const removeComponent = (id) => {
    const oldProperties = form.getFieldValue('properties')
    const newProperties = {
      ...oldProperties,
      components: oldProperties.components.filter((el) => el.id !== id)
    }
    form.setFieldsValue({ properties: newProperties })
  }
  const updateComponents = (components) => {
    const oldProperties = form.getFieldValue('properties')
    form.setFieldsValue({
      properties: { ...oldProperties, components }
    })
  }
  return (
    <Row>
      <Col xs={24}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          onKeyDown={onKeyDownForm}
        >
          <Form.Item
            name="name"
            label={fm('label.name')}
            rules={[
              {
                required: true,
                message: 'Please input name'
              }
            ]}
          >
            <Input style={{ width: 400 }} />
          </Form.Item>
          <Form.Item name="tags" label={fm('label.tags')}>
            <Select mode="tags" style={{ width: 400 }}>
              {tags}
            </Select>
          </Form.Item>
          <Card>
            <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
              {({ namespace, translation }) =>
                <>
                  <Form.Item
                    label={'Collection title'}
                    name={[namespace, 'title']}
                  >
                    <Input />
                  </Form.Item>
                </>
              }
            </TranslationTabs>
          </Card>
          <Form.Item shouldUpdate>
            {(f) => {
              const properties = f.getFieldValue('properties')
              return (
                <ComponentsTable
                  components={
                    properties && properties.components
                      ? properties.components
                      : []
                  }
                  updateComponents={updateComponents}
                  saveComponent={saveComponent}
                  removeComponent={removeComponent}
                />
              )
            }}
          </Form.Item>
          <FormActionBox
            isLoading={isLoading}
            newRecord={newRecord}
            onDelete={onDelete}
          />
        </Form>
      </Col>
    </Row>
  )
}
