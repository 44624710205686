import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Typography, DatePicker, Select, Radio, Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { useDebounce } from 'use-debounce'
import _ from 'lodash-contrib'

import { useDispatch, useSelector } from 'react-redux'
import { changeOrdersFilters } from '../../data/actions/orders'
import { ProductVariantSelect } from '../Shared/ProductVariantSelect'
import { RAILS_SERVER } from '../../constants/api'

const { RangePicker } = DatePicker
const { Text, Title } = Typography

export const OrdersTableHeader = ({ updatePage }) => {
  const dispatch = useDispatch()
  const searchValue = useSelector((state) => state.orders.filtersEntries.term)
  const vouchers = useSelector((state) => state.vouchers.entries)
  const discounts = useSelector((state) => state.discounts.entries)
  const shops = useSelector((state) => state.shops.entries)
  const countries = useSelector((state) => state.countries.entries)
  const countriesIndex = _.keyBy(countries, 'id')
  const states = useSelector((state) => state.states.entries)
  const statesIndex = _.groupBy(states, 'countryId')
  const filters = useSelector((state) => state.orders.filtersEntries)
  const {
    pagination, period, productVariantIds, voucherId, discountId,
    shopId, countryIds, stateIds, stateNames, paymentMethods, paid
  } = filters
  const [term, setTerm] = useState(searchValue)
  const [value] = useDebounce(term, 500)
  const searchQuery = {
    filters: _.omitBy({
      term,
      period,
      product_variant_ids: productVariantIds,
      voucher_id: voucherId,
      discount_id: discountId,
      shop_id: shopId,
      country_ids: countryIds,
      state_ids: stateIds,
      state_names: stateNames,
      payment_methods: paymentMethods,
      paid
    }, (v) => !_.isBoolean(v) && _.isEmpty(v))
  }
  const exportOrdersUrl = `${RAILS_SERVER}/api/v1/export/orders.csv?${_.toQuery(searchQuery)}`
  const exportOrderItemsUrl = `${RAILS_SERVER}/api/v1/export/order_items.csv?${_.toQuery(searchQuery)}`
  const onChangeNameOrEmail = (e) => {
    setTerm(e.target.value)
  }
  const selectPaymentMethod = (value) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'paymentMethods', value },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearPaymentMethod = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'paymentMethods', value: null },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearProductVariants = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'productVariantIds', value: [] },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectProductVariants = (productVariantIds) => {
    const value = productVariantIds.flatMap((id) => {
      const ids = id.split(',')
      return ids.length > 1 ? ids.splice(1) : ids
    })

    changeOrdersFilters(dispatch, [
      { valueType: 'productVariantIds', value },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearVoucher = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'voucherId', value: null },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectVoucher = (value) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'voucherId', value },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearDiscount = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'discountId', value: null },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectDiscount = (value) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'discountId', value },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearShop = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'shopId', value: null },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectShop = (value) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'shopId', value },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearCountries = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'countryIds', value: [] },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectCountries = (values) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'countryIds', value: values },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearStates = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'stateIds', value: [] },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectStates = (values) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'stateIds', value: values },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const clearStateNames = () => {
    changeOrdersFilters(dispatch, [
      { valueType: 'stateNames', value: [] },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectStateNames = (values) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'stateNames', value: values },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const selectPaid = (e) => {
    changeOrdersFilters(dispatch, [
      { valueType: 'paid', value: e.target.value },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }
  const changePeriod = (values) => {
    if (values) {
      const startDate = values[0].format('DD-MM-Y')
      const endDate = values[1].format('DD-MM-Y')
      changeOrdersFilters(dispatch, [
        { valueType: 'period', value: [startDate, endDate] },
        { valueType: 'pagination', value: { ...pagination, current: 1 } }
      ])
    } else {
      changeOrdersFilters(dispatch, [
        { valueType: 'period', value: [] },
        { valueType: 'pagination', value: { ...pagination, current: 1 } }
      ])
    }
  }
  useEffect(() => {
    changeOrdersFilters(dispatch, [
      { valueType: 'term', value },
      { valueType: 'pagination', value: { ...pagination, current: 1 } }
    ])
  }, [dispatch, value])

  return (
    <>
      <Row gutter={[16, 4]}>
        <Col xs={24}>
          <Title level={5}>Filters</Title>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Enter number or email</Text>
            </Col>
            <Col xs={24}>
              <Input
                allowClear
                onChange={onChangeNameOrEmail}
                defaultValue={term}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Period</Text>
            </Col>
            <Col xs={24}>
              <RangePicker onChange={changePeriod} />
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={24}>
              <Text>Payment method</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="multiple"
                allowClear
                onChange={selectPaymentMethod}
                style={{ width: '100%' }}
                value={paymentMethods}
                onClear={clearPaymentMethod}
              >
                <Select.Option key={'stripe'} value={'stripe'}>
                  Stripe
                </Select.Option>
                <Select.Option key={'apple-pay'} value={'apple-pay'}>
                  Apple Pay
                </Select.Option>
                <Select.Option key={'google-pay'} value={'google-pay'}>
                  Google Pay
                </Select.Option>
                <Select.Option key={'klarna'} value={'klarna'}>
                  Klarna
                </Select.Option>
                <Select.Option key={'paypal'} value={'paypal'}>
                  Paypal
                </Select.Option>
                <Select.Option key={'paypal-card'} value={'paypal-card'}>
                  Paypal Card
                </Select.Option>
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={8}>
          <Row>
            <Col xs={24}>
              <Text>Product variants</Text>
            </Col>
            <Col xs={24}>
              <ProductVariantSelect
                allowClear
                onChange={selectProductVariants}
                style={{ width: '100%' }}
                value={productVariantIds}
                onClear={clearProductVariants}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 4]}>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Voucher</Text>
            </Col>
            <Col xs={24}>
              <Select
                allowClear
                onChange={selectVoucher}
                style={{ width: '100%' }}
                value={voucherId}
                onClear={clearVoucher}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {vouchers.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {`${el.name} (${el.code})`}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Discount</Text>
            </Col>
            <Col xs={24}>
              <Select
                allowClear
                onChange={selectDiscount}
                style={{ width: '100%' }}
                value={discountId}
                onClear={clearDiscount}
              >
                {discounts.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          <Row>
            <Col xs={24}>
              <Text>Marketplace</Text>
            </Col>
            <Col xs={24}>
              <Select
                allowClear
                onChange={selectShop}
                style={{ width: '100%' }}
                value={shopId}
                onClear={clearShop}
              >
                {shops.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Payment status</Text>
            </Col>
            <Col xs={24}>
              <Radio.Group value={paid} onChange={selectPaid}>
                <Radio.Button>All</Radio.Button>
                <Radio.Button value={true}>Paid</Radio.Button>
                <Radio.Button value={false}>Unpaid</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 4]}>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Countries</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="multiple"
                allowClear
                onChange={selectCountries}
                style={{ width: '100%' }}
                value={countryIds}
                onClear={clearCountries}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {countries.map((el) => {
                  return (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  )
                })}
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>States</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="multiple"
                allowClear
                onChange={selectStates}
                style={{ width: '100%' }}
                value={stateIds}
                onClear={clearStates}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                options={
                  _.sortBy(
                    _.filter(
                      countryIds.length ? countryIds : Object.keys(countriesIndex),
                      (countryId) => statesIndex[countryId]
                    ).map((countryId) => ({
                      label: countriesIndex[countryId].name,
                      options: statesIndex[countryId].map((state) => ({ label: state.name, value: state.id }))
                    })),
                    'label'
                  )
                }
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>State names</Text>
            </Col>
            <Col xs={24}>
              <Select
                mode="tags"
                allowClear
                onChange={selectStateNames}
                style={{ width: '100%' }}
                value={stateNames}
                onClear={clearStateNames}
              >
              </Select>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row style={{ paddingTop: '22px' }}>
            <Col xs={12}>
              <Button type="primary" icon={<DownloadOutlined />} href={exportOrdersUrl}>
                Export orders CSV
              </Button>
            </Col>
            <Col xs={12}>
              <Button type="primary" icon={<DownloadOutlined />} href={exportOrderItemsUrl}>
                Export order items CSV
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
