import { vimeoIframe } from './vimeoIframe'

export default class VimeoBlock {
  static get toolbox() {
    return {
      icon: '<span>V</span>',
      title: 'Vimeo'
    }
  }

  static get DEFAULT_CODE_PLACEHOLDER() {
    return 'Enter a vimeo code'
  }

  get CSS() {
    return {
      baseClass: this.api.styles.block,
      wrapper: 'vimeo',
      text: 'vimeo__text',
      input: this.api.styles.input
    }
  }

  get settings() {
    return []
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api
    this.readOnly = readOnly

    this.codePlaceholder =
      config.codePlaceholder || VimeoBlock.DEFAULT_CODE_PLACEHOLDER

    this.data = {
      code: data.code || ''
    }
    this.container = this._make('blockquote', [
      this.CSS.baseClass,
      this.CSS.wrapper
    ])
  }

  render() {
    const code = this._make('input', [this.CSS.input, this.CSS.text], {
      value: this.data.code
    })
    code.dataset.placeholder = this.codePlaceholder
    this.iframe = this._make('div', [], {
      innerHTML: vimeoIframe(this.data.code)
    })
    code.addEventListener(
      'keyup',
      (e) => {
        this.updateIframe(e.target.value)
      },
      this
    )
    this.container.appendChild(this.iframe)
    this.container.appendChild(code)
    return this.container
  }

  updateIframe(code) {
    this.iframe.innerHTML = vimeoIframe(code)
  }

  save(codeElement) {
    const code = codeElement.querySelector(`.${this.CSS.text}`)
    return Object.assign(this.data, {
      code: code.value
    })
  }

  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName)
    if (Array.isArray(classNames)) {
      el.classList.add(...classNames)
    } else if (classNames) {
      el.classList.add(classNames)
    }
    for (const attrName in attributes) {
      el[attrName] = attributes[attrName]
    }
    return el
  }
}
