import React from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { fm } from '../../../lang'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { getFilteredArticles } from '../../../data/selectors/articles'
import { ArticlesTableHeader } from './ArticlesTableHeader'
import { WebsitesCell } from '../../Shared/WebsitesCell'
import { SHOP_URL } from '../../../constants/api'

export const ArticlesTable = ({ isLoading, path }) => {
  const columns = [
    {
      title: fm('articles.table.title'),
      key: 'title',
      render: ({ translations }) => (
        translations.find(({ locale }) => locale === 'en').title
      )
    },
    {
      title: fm('table.websites'),
      key: 'websiteIds',
      dataIndex: 'websiteIds',
      width: 150,
      render: (websiteIds, record) => (
        <WebsitesCell websiteIds={websiteIds}>
          {({ website, name }) => {
            let pageUrl = null
            if (record.published) {
              const websiteTranslation = record.translations.find(({ locale }) => locale === website.locale)
              const defaultTranslation = record.translations.find(({ locale }) => locale === 'en')
              const slug = (websiteTranslation && websiteTranslation.slug) || defaultTranslation.slug

              pageUrl = new URL(`${website.prefix}/blog/${slug}`, SHOP_URL)
            } else {
              pageUrl = new URL(`${website.prefix}/blog/${record.id}`, SHOP_URL)
            }
            return (<a href={pageUrl} target="_blank" rel="noreferrer">{name}</a>)
          }}
        </WebsitesCell>
      )
    },
    {
      title: fm('table.published-at'),
      key: 'publishedAt',
      dataIndex: 'publishedAt',
      width: 200,
      render: (publishedAt, record) => record.published && publishedAt && moment(publishedAt).format('MMMM Do YYYY')
    },
    {
      title: fm('table.actions'),
      key: 'slug',
      dataIndex: 'slug',
      width: 200,
      render: (slug, record) => (
        <>
          <Button
            onClick={() => {
              push(`/content/articles/${record.id}`)
            }}
          >
            Edit
          </Button>
        </>
      )
    }
  ]
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const entries = useSelector(getFilteredArticles)
  const handleClickAdd = () => {
    push('/content/articles/new')
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('articles.title')}
      extra={
        allow('can_create_article') && (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size={'small'}>
        <ArticlesTableHeader />
      </Card>
      <Card size={'small'}>
        <Table
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey={'id'}
        />
      </Card>
    </PageHeader>
  )
}
