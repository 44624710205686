import { createSelector } from 'reselect'

const getPages = (state) => state.pages.entries
const getFilters = (state) => state.pages.tableFilters
const pageComponents = (state) => state.pages.entry.components
const getComponents = (state) => state.blocks.entries

export const getPageComponents = createSelector(
  pageComponents,
  getComponents,
  (pComponents, components) => {
    if (pComponents.length === 0) return pComponents
    return pComponents
      .filter((b) => !b._destroy)
      .map((b) => {
        const currentItem = components.find((i) => i.id === b.blockableId)
        return { ...b, name: currentItem.name }
      })
  }
)

export const getFilteredPages = createSelector(
  getPages,
  getFilters,
  (pages, filters) => {
    if (pages.length === 0) return []
    let result = pages
    if (filters.term && filters.term !== '') {
      result = result.filter((p) =>
        p.name.toLowerCase().includes(filters.term.toLowerCase()) ||
          p.translations.some((t) => t.slug && t.slug.toLowerCase().includes(filters.term.toLowerCase()))
      )
    }
    if (filters.kind && filters.kind !== '') {
      result = result.filter((p) => p.kind === filters.kind)
    }
    if (filters.website && filters.website !== '') {
      result = result.filter((p) => p.websiteIds.includes(filters.website))
    }
    switch (filters.publishedStatus) {
      case 'all':
        break
      case 'published':
        result = result.filter((p) => p.publishedAt)
        break
      case 'draft':
        result = result.filter((p) => !p.publishedAt)
        break
    }
    return result
  }
)
