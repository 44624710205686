import * as types from '../../constants/actionTypes/websitesActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyWebsite = {
  id: null,
  name: '',
  prefix: '',
  locale: '',
  shopId: '',
  countryIds: [],
  defaultCountryId: ''
}

const defaultState = {
  entry: { ...emptyWebsite, id: uuidv4() },
  entries: []
}

export default function websitesReducer(state = defaultState, action) {
  switch (action.type) {
    case types.WEBSITES_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.website.id) {
            return { ...action.website }
          } else {
            return u
          }
        })
      }
    case types.WEBSITES_DELETE:
      return {
        ...state,
        entry: { ...emptyWebsite, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.website.id)
      }
    case types.WEBSITE_FETCH:
      return { ...state, entry: action.data }
    case types.WEBSITES_CREATE:
      return {
        ...state,
        entries: [{ ...action.website }, ...state.entries]
      }
    case types.WEBSITE_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.WEBSITE_FORM_RESET:
      return { ...state, entry: { ...emptyWebsite, id: uuidv4() } }
    case types.WEBSITES_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
