import React from 'react'
import { Row, Col, Form, Tabs } from 'antd'
import { onKeyDownForm } from '../../../../helpers/form'
import { useDispatch } from 'react-redux'
import { FormActionBox } from '../../../Shared/FormActionBox'
import { removeFile } from '../../../../data/actions/blocks'
import { useRecordHook } from '../../../../helpers/hooks/useRecordHook'
import { LinksTable } from './LinksTable'
import { v4 as uuidv4 } from 'uuid'
import { PicturesWall } from '../../../Shared/PicturesWall'

export const HeaderBlock = ({ form, onFinish, isLoading }) => {
  const dispatch = useDispatch()
  const { params } = useRecordHook()
  const { id } = params
  const updateLinks = (links) => {
    const oldProperties = form.getFieldValue('properties')
    form.setFieldsValue({
      properties: { ...oldProperties, links }
    })
  }

  const saveLink = (link) => {
    const oldProperties = form.getFieldValue('properties')
    let newProperties = []
    if (link.id) {
      newProperties = {
        ...oldProperties,
        links: oldProperties.links.map((el) => {
          if (el.id === link.id) {
            return link
          } else {
            return el
          }
        })
      }
    } else {
      newProperties = {
        ...oldProperties,
        links: oldProperties.links
          ? oldProperties.links.concat({ ...link, id: uuidv4() })
          : [{ ...link, id: uuidv4() }]
      }
    }
    form.setFieldsValue({ properties: newProperties })
  }
  const removeLink = (linkId) => {
    const oldProperties = form.getFieldValue('properties')
    const newProperties = {
      ...oldProperties,
      links: oldProperties.links.filter((el) => el.id !== linkId)
    }
    form.setFieldsValue({ properties: newProperties })
  }
  const afterRemoveFile = (file) => {
    removeFile(dispatch, id, file.blobId)
  }
  const afterUploadFile = (file) => {
    form.setFieldsValue({
      files: [...form.getFieldValue('files'), { ...file }]
    })
  }
  return (
    <Row>
      <Col xs={24}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          onKeyDown={onKeyDownForm}
        >
          <Tabs
            defaultActiveKey={'links'}
            items={[{
              key: 'links',
              label: 'Links',
              children: (
                <Form.Item shouldUpdate>
                  {(f) => {
                    const properties = f.getFieldValue('properties')
                    const files = f.getFieldValue('files')
                    return (
                      <LinksTable
                        files={files}
                        links={
                          properties && properties.links
                            ? properties.links
                            : []
                        }
                        updateLinks={updateLinks}
                        saveLink={saveLink}
                        removeLink={removeLink}
                      />
                    )
                  }}
                </Form.Item>
              )
            }, {
              key: 'images',
              label: 'Images',
              children: (
                <Form.Item shouldUpdate>
                  {(f) => {
                    const files = form.getFieldValue('files')
                    return (
                      <PicturesWall
                        afterRemoveFile={afterRemoveFile}
                        afterUploadFile={afterUploadFile}
                        newRecord={false}
                        files={files || []}
                        recordType={'block'}
                        recordId={id}
                        showCodeButton={false}
                      />
                    )
                  }}
                </Form.Item>
              )
            }]}
          />
          <FormActionBox
            isLoading={isLoading}
            newRecord={false}
          />
        </Form>
      </Col>
    </Row>
  )
}
