import React from 'react'
import { Row, Col, Card, Button, Popconfirm, Form } from 'antd'
import { css } from 'linaria'
import { fm } from '../../lang'

const boxWrapper = css`
  position: fixed;
  bottom: 0;
  width: calc(100% - 265px);
  z-index: 100;
`
export const FormActionBox = ({
  isLoading,
  newRecord,
  onDelete
}) => {
  const submitText = newRecord ? 'create' : 'update'
  const disabledButton = isLoading

  return (
    <div className={boxWrapper}>
      <Form.Item shouldUpdate style={{ margin: 0 }}>
        {() => (
          <Card size="small">
            <Row align={'middle'} justify={'space-between'}>
              <Col>
                <Button type={'primary'} htmlType={'submit'} disabled={disabledButton}>
                  {fm(`button.${submitText}`)}
                </Button>
              </Col>
              <Col>
                {!newRecord && onDelete && (
                  <Popconfirm
                    placement={'topRight'}
                    title={fm('action-box.message-delete')}
                    onConfirm={onDelete}
                    okText={fm('button.yes')}
                    cancelText={fm('button.no')}
                  >
                    <Button type={'danger'} disabled={disabledButton}>
                      {fm('button.delete')}
                    </Button>
                  </Popconfirm>
                )}
              </Col>
            </Row>
          </Card>
        )}
      </Form.Item>
    </div>
  )
}
