import React from 'react'
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (rest.isAuthenticated) {
        return rest.allow
          ? <Component {...props} {...rest.componentProps} />
          : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      } else {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    }}
  />
)
