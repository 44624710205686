import React from 'react'
import { Button, Col, Form, Input, List, Row } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import {
  DeleteOutlined,
  PlusOutlined,
  CheckCircleTwoTone
} from '@ant-design/icons'

import { fm } from '../../../lang'
import { langList } from '../../../constants/langList'
import { capitalizeToLower } from '../../../helpers/string'
import { PicturesWall } from '../../Shared/PicturesWall'

const emptyAudio = {
  id: null,
  blobId: null,
  titleEn: '',
  titleFr: '',
  titleDe: ''
}

export const AudioFields = ({ productId, newRecord, afterUploadAudio }) => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Form.List name="audios">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() =>
                    add({
                      ...emptyAudio,
                      productId,
                      id: uuidv4()
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                >
                  {fm('button.add-audio')}
                </Button>
              </Form.Item>
              <List
                itemLayout="horizontal"
                dataSource={fields}
                renderItem={(field) => (
                  <List.Item
                    actions={[
                      <Button
                        key="remove-item"
                        onClick={() => remove(field.name)}
                        type="danger"
                        icon={<DeleteOutlined />}
                        style={{ zIndex: 100000, marginTop: 16 }}
                      />
                    ]}
                  >
                    <Row gutter={[16, 16]}>
                      {langList.map((el) => {
                        return (
                          <Col
                            key={[
                              field.key,
                              `col-title${capitalizeToLower(el)}`
                            ]}
                          >
                            <Form.Item
                              {...field}
                              label={fm('label.lang-title', { lang: el })}
                              name={[
                                field.name,
                                `title${capitalizeToLower(el)}`
                              ]}
                              fieldKey={[
                                field.key,
                                `title${capitalizeToLower(el)}`
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        )
                      })}
                      <Col>
                        <Form.Item
                          label={fm('label.audio-file')}
                          dependencies={['audios', field.key, 'blobId']}
                        >
                          {(f) => {
                            const blobId = f.getFieldValue('audios')[field.key].blobId
                            return (
                              <>
                                <PicturesWall
                                  onlyButton={true}
                                  newRecord={newRecord}
                                  listType={'text'}
                                  fileType={'audio'}
                                  recordType={'product'}
                                  data={{ prev_blob_id: blobId }}
                                  showCodeButton={false}
                                  recordId={productId}
                                  files={[]}
                                  afterUploadFile={(file) =>
                                    afterUploadAudio(field.key, file)
                                  }
                                />
                                {blobId && (
                                  <span>
                                    <CheckCircleTwoTone
                                      twoToneColor="#52c41a"
                                      style={{ marginRight: 4 }}
                                    />
                                    {fm('label.audio-file-attached')}
                                  </span>
                                )}
                              </>
                            )
                          }}
                        </Form.Item>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            </>
          )}
        </Form.List>
      </Col>
    </Row>
  )
}
