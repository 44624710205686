import * as types from '../../constants/actionTypes/authActionTypes'
import { getToken } from '../../helpers/auth'

const localData = getToken()
const emptyUserData = {
  userId: null,
  firstName: '',
  lastName: '',
  email: ''
}

const defaultState = {
  lang: 'en',
  userData: localData
    ? {
        userId: localData.userId,
        firstName: localData.firstName,
        lastName: localData.lastName,
        email: localData.email
      }
    : emptyUserData,
  isAuthenticated: !!localData,
  isAuthenticating: false,
  loggedIn: false
}

export default function authReducer(state = defaultState, action) {
  switch (action.type) {
    case types.AUTH_REQUEST:
      return { ...state, isAuthenticating: true, isAuthenticated: false }
    case types.AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        userData: {
          userId: action.data.userId,
          email: action.data.email,
          firstName: action.data.firstName,
          lastName: action.data.lastName
        }
      }
    case types.AUTH_ANONYMOUS:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        userData: emptyUserData
      }
    case types.AUTH_ERROR:
      return { ...state, isAuthenticating: false, isAuthenticated: false }
    case types.AUTH_SIGN_OUT:
      return { ...state, isAuthenticated: false }
    default:
      return state
  }
}
