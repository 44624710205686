import React from 'react'
import { PrivateRoute } from './PrivateRoute'
import { AccountScreen } from '../screens/AccountScreen'

export const SharedRoute = ({ isAuthenticated }) => {
  return (
    <PrivateRoute
      allow={true}
      path="/account"
      exact
      isAuthenticated={isAuthenticated}
      component={AccountScreen}
    />
  )
}
