import React from 'react'
import { Form, Input } from 'antd'

import { fm } from '../../lang'

const { TextArea } = Input

export const TranslationFields = ({ namespace, locale }) => {
  return (
    <>
      <Form.Item
        label={fm('label.translations.slug')}
        name={[namespace, 'slug']}
        required={locale === 'en'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.title')}
        name={[namespace, 'title']}
        required={locale === 'en'}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={fm('label.translations.details')}
        name={[namespace, 'details']}
      >
        <TextArea rows={4} />
      </Form.Item>
    </>
  )
}
