export const vimeoIframe = (code) => {
  return `<div style="padding:56.25% 0 0 0;position:relative;">
      <iframe
            src="https://player.vimeo.com/video/${code}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=fe07c239ef"
            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
            style="border-radius: 24px;position:absolute;top:0;left:0;width:100%;height:100%;"
            title="Luxe (FM &amp;amp; DAB) Instructional Videos_2nd Draft_Updated.mp4"></iframe>
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>`
}
