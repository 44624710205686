import React from 'react'
import { Switch } from 'react-router-dom'
import { PrivateRoute } from '../PrivateRoute'
import { FreeFormsScreen } from '../../screens/FreeForms/FreeFormsScreen'
import { FreeFormFormScreen } from '../../screens/FreeForms/FreeFormFormScreen'
import { useSubscription } from '@logux/redux'
import { useAllow } from '../../helpers/hooks/usePermissions'

export const FreeFormsRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['free_forms'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_free_form')}
        path="/free_forms/new"
        exact
        isAuthenticated={isAuthenticated}
        component={FreeFormFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_free_form')}
        path="/free_forms/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={FreeFormFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_free_forms')}
        path="/free_forms"
        exact
        isAuthenticated={isAuthenticated}
        component={() => <FreeFormsScreen isLoading={isLoading} />}
      />
    </Switch>
  )
}
