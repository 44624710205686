import * as types from '../../constants/actionTypes/specificationGroupsActionTypes'

export const createSpecificationGroup = (dispatch, params) => {
  return dispatch.sync({
    type: types.SPECIFICATION_GROUPS_CREATE,
    record: params
  })
}

export const updateSpecificationGroup = (dispatch, params) => {
  return dispatch.sync({
    type: types.SPECIFICATION_GROUPS_UPDATE,
    record: params
  })
}

export const updateSpecificationGroupPosition = (dispatch, entries) => {
  dispatch.sync({
    type: types.SPECIFICATION_GROUPS_UPDATE_POSITION,
    records: entries
  })
}

export const deleteSpecificationGroup = (dispatch, id, push) => {
  push('/assortment/specification-groups')
  dispatch.sync({
    type: types.SPECIFICATION_GROUPS_DELETE,
    record: { id }
  })
}

export const selectSpecificationGroup = (dispatch, id) => {
  dispatch.local({
    type: types.SPECIFICATION_GROUP_SELECT,
    id
  })
}

export const resetSpecificationGroup = (dispatch, id) => {
  dispatch.local({
    type: types.SPECIFICATION_GROUP_FORM_RESET,
    id
  })
}
