import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Drawer, Collapse } from 'antd'
import _ from 'lodash'

const { Panel } = Collapse

export const DropsDocumentation = () => {
  const [open, setOpen] = useState(false)
  const { dropsDocumentation } = useSelector((state) => state.account.entry)

  const openDrawer = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <>
      <Button type="link" onClick={openDrawer}>
        Drops documentation
      </Button>
      <Drawer title="Drops documentation" placement="right" onClose={handleClose} open={open}>
        <Collapse accordion ghost>
          {Object.keys(dropsDocumentation).sort().map((dropName) => (
            <Panel header={dropName} key={dropName}>
              {Object.keys(dropsDocumentation[dropName]).sort().map((methodName) => (
                <p key={methodName}>
                  <b>
                    {methodName}: {
                      _.isEmpty(dropsDocumentation[dropName][methodName].returns)
                        ? ''
                        : `(${dropsDocumentation[dropName][methodName].returns})`
                    }
                  </b> {
                    _.isEmpty(dropsDocumentation[dropName][methodName].description)
                      ? <i>No docs</i>
                      : dropsDocumentation[dropName][methodName].description
                  }
                </p>
              ))}
            </Panel>
          ))}
        </Collapse>
      </Drawer>
    </>
  )
}
