export const setToken = (response) => {
  const tokenParams = {
    token: response.token,
    userId: response.userId,
    email: response.email,
    firstName: response.firstName,
    lastName: response.lastName
  }
  localStorage.setItem(
    `${process.env.NODE_ENV}.lumie.jwt`,
    JSON.stringify(tokenParams)
  )
}

export const resetToken = () => {
  localStorage.removeItem(`${process.env.NODE_ENV}.lumie.jwt`)
}

export const getToken = () => {
  const token = localStorage.getItem(`${process.env.NODE_ENV}.lumie.jwt`)
  return token ? JSON.parse(token) : undefined
}
