import React, { useEffect } from 'react'
import { Modal, Select, Row, Col, Form, Input } from 'antd'
import { CollectionModalForm } from './CollectionModalForm'
import { css } from 'linaria'
import { HtmlModalForm } from './HtmlModalForm'

const { Option } = Select

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`
export const ModalForm = ({
  saveComponent,
  component,
  setModalOpen,
  modalOpen
}) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.setFieldsValue(component)
  }, [component, form])

  const handleOk = () => {
    form.submit()
  }

  const handleCancel = () => {
    setModalOpen(false)
  }

  const onFinish = () => {
    const values = form.getFieldsValue(true)
    saveComponent(values)
    setModalOpen(false)
  }

  return (
    <>
      <Modal
        title="Component"
        open={modalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        <Form
          initialValues={component}
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          className={formClass}
        >
          <Row>
            <Form.Item
              label={'Name'}
              name={'name'}
              rules={[
                {
                  required: true,
                  message: 'Please input name'
                }
              ]}
            >
              <Input placeholder={'Input a name'} />
            </Form.Item>
            <Col xs={24}>
              <Form.Item name="type" label={'Type'}>
                <Select placeholder={'Select type'}>
                  <Option value="html">HTML</Option>
                  <Option value="collection">Collection</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item shouldUpdate>
                {(f) => {
                  const type = f.getFieldValue('type')
                  return (
                    <>
                      {type === 'collection' && <CollectionModalForm />}
                      {type === 'html' && <HtmlModalForm />}
                    </>
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
