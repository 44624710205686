import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Typography } from 'antd'
import { useDebounce } from 'use-debounce'

import { useDispatch, useSelector } from 'react-redux'
import { changeRedirectsFilters } from '../../../data/actions/redirects'
const { Text, Title } = Typography
export const RedirectsTableHeader = ({ updatePage }) => {
  const dispatch = useDispatch()
  const searchValue = useSelector((state) => state.redirects.filtersEntries.term)
  const [term, setTerm] = useState(searchValue)
  const [value] = useDebounce(term, 500)
  const onChangeTitle = (e) => {
    setTerm(e.target.value)
  }
  useEffect(() => {
    changeRedirectsFilters(dispatch, [
      { valueType: 'term', value }
    ])
  }, [dispatch, value])
  return (
    <>
      <Row gutter={[16, 4]}>
        <Col xs={24}>
          <Title level={5}>Filters</Title>
        </Col>
        <Col xs={6}>
          <Row>
            <Col xs={24}>
              <Text>Search value</Text>
            </Col>
            <Col xs={24}>
              <Input
                allowClear
                onChange={onChangeTitle}
                defaultValue={term}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[16, 4]}>
      </Row>
    </>
  )
}
