import * as types from '../../constants/actionTypes/permissionsActionTypes'

const defaultState = {
  entries: []
}

export default function permissionsReducer(state = defaultState, action) {
  switch (action.type) {
    case types.PERMISSIONS_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
