import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export const useMenu = () => {
  const location = useLocation()
  const [currentMenu, setCurrentMenu] = useState([])
  const [subMenu, setSubMenu] = useState([])
  useEffect(() => {
    if (location.pathname.includes('/settings')) {
      if (!subMenu.includes('settings')) {
        setSubMenu([...subMenu, 'settings'])
      }
      if (location.pathname.includes('/settings/roles')) {
        setCurrentMenu(['/settings/roles'])
      } else if (location.pathname.includes('/settings/users')) {
        setCurrentMenu(['/settings/users'])
      } else if (location.pathname.includes('/settings/shops')) {
        setCurrentMenu(['/settings/shops'])
      } else {
        setCurrentMenu([])
      }
    } else if (location.pathname.includes('/content')) {
      if (!subMenu.includes('content')) {
        setSubMenu([...subMenu, 'content'])
      }
      if (location.pathname.includes('/content/websites')) {
        setCurrentMenu(['/content/websites'])
      } else if (location.pathname.includes('/content/pages')) {
        setCurrentMenu(['/content/pages'])
      } else if (location.pathname.includes('/content/redirects')) {
        setCurrentMenu(['/content/redirects'])
      } else if (location.pathname.includes('/content/blocks')) {
        setCurrentMenu(['/content/blocks'])
      } else if (location.pathname.includes('/content/articles')) {
        setCurrentMenu(['/content/articles'])
      } else if (location.pathname.includes('/content/tags')) {
        setCurrentMenu(['/content/tags'])
      } else {
        setCurrentMenu([])
      }
    } else if (location.pathname.includes('/customers')) {
      setCurrentMenu(['/customers'])
    } else if (location.pathname.includes('/assortment')) {
      if (!subMenu.includes('assortment')) {
        setSubMenu([...subMenu, 'assortment'])
      }
      if (location.pathname.includes('/assortment/products')) {
        setCurrentMenu(['/assortment/products'])
      } else if (location.pathname.includes('/assortment/categories')) {
        setCurrentMenu(['/assortment/categories'])
      } else if (location.pathname.includes('/assortment/specification-groups')) {
        setCurrentMenu(['/assortment/specification-groups'])
      } else if (location.pathname.includes('/assortment/specifications')) {
        setCurrentMenu(['/assortment/specifications'])
      } else {
        setCurrentMenu([])
      }
    } else if (location.pathname.includes('/orders')) {
      setCurrentMenu(['/orders'])
    } else if (location.pathname.includes('/discounts')) {
      setCurrentMenu(['/discounts'])
    } else if (location.pathname.includes('/vouchers')) {
      setCurrentMenu(['/vouchers'])
    } else if (location.pathname === '/') {
      setCurrentMenu(['/'])
    } else {
      setCurrentMenu([])
    }
  }, [location, subMenu])

  return { currentMenu, subMenu, setSubMenu }
}
