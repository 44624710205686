import { useSelector } from 'react-redux'
import { permissionsArray } from '../../constants/permissionsArray'

export const isAllowed = (rights, key) =>
  rights &&
  permissionsArray[key] &&
  rights.some((r) => permissionsArray[key].includes(r))

export const useAllow = () => {
  const { permissions } = useSelector((state) => state.account.entry)
  return (key) => isAllowed(permissions, key)
}
