import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, Form, Select } from 'antd'

export const AddTranslationModal = ({ open, onAdd, onCancel, usedLocales }) => {
  const [form] = Form.useForm()
  const websites = useSelector((state) => state.websites.entries)
  const websiteLocales = websites.reduce((result, website) => (
    { ...result, [website.locale]: [...(result[website.locale] ?? []), website.name] }
  ), {})
  const selectOptions = Object.keys(websiteLocales).map(locale =>
    [locale, `${websiteLocales[locale].join(', ')} (${locale})`])

  return (
    <Modal
      open={open}
      title="Add new website locale"
      okText="Add"
      cancelText="Cancel"
      onOk={form.submit}
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
    >
      <Form
        form={form}
        onFinish={(values) => {
          onAdd(values)
          form.resetFields()
        }}
        initialValues={{ locale: null }}
      >
        <Form.Item
          name="locale"
          label="Locale"
          rules={[{ required: true, message: 'Please pick the locale' }]}
        >
          <Select placeholder="Select a locale">
            {
              selectOptions.map(([locale, name]) => (
                <Select.Option
                  value={locale}
                  key={locale}
                  disabled={usedLocales.includes(locale)}
                >
                  {name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}
