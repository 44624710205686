import React from 'react'
import { EditorComponent } from '../EditorComponent'
import { RAILS_SERVER } from '../../../constants/api'

export const TranslationEditor = ({ uploadPath, namespace, data, entryId, instanceRef, pluginList }) => {
  const uploadUrl = `${RAILS_SERVER}${uploadPath}`

  return (
    <EditorComponent
      instanceRef={instanceRef}
      uploadUrl={uploadUrl}
      data={data}
      entryId={`${entryId}/${namespace}`}
      pluginList={pluginList}
    />
  )
}
