import { useEffect } from 'react'
import { message } from 'antd'
import { useSelector } from 'react-redux'

export const useErrorHook = () => {
  const { errorMessage, errorTrigger } = useSelector((state) => state.ui)
  const error = () => {
    if (errorMessage !== '') {
      message.error(errorMessage)
    }
  }
  useEffect(() => {
    error()
  }, [errorTrigger])
  return true
}
