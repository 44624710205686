import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'
import { PrivateRoute } from '../PrivateRoute'
import { RolesScreen } from '../../screens/Roles/RolesScreen'
import { RoleFormScreen } from '../../screens/Roles/RoleFormScreen'
import { useAllow } from '../../helpers/hooks/usePermissions'

export const RolesRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['roles', 'permissions'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_role')}
        path="/settings/roles/new"
        exact
        isAuthenticated={isAuthenticated}
        component={RoleFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_role')}
        path="/settings/roles/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={RoleFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_roles')}
        path="/settings/roles"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={RolesScreen}
      />
    </Switch>
  )
}
