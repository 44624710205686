import { createSelector } from 'reselect'

const getArticles = (state) => state.articles.entries
const getFilters = (state) => state.articles.tableFilters

export const getFilteredArticles = createSelector(
  getArticles,
  getFilters,
  (articles, filters) => {
    if (articles.length === 0) return []
    let result = articles
    if (filters.term && filters.term !== '') {
      result = result.filter((p) =>
        p.translations.some((t) => t.title && t.title.toLowerCase().includes(filters.term.toLowerCase())) ||
          p.translations.some((t) => t.slug && t.slug.toLowerCase().includes(filters.term.toLowerCase()))
      )
    }
    if (filters.website && filters.website !== '') {
      result = result.filter((p) => p.websiteIds.includes(filters.website))
    }
    switch (filters.publishedStatus) {
      case 'all':
        break
      case 'published':
        result = result.filter((p) => p.published)
        break
      case 'draft':
        result = result.filter((p) => !p.published)
        break
    }
    return result
  }
)
