import { combineReducers } from 'redux'
import auth from './authReducer'
import ui from './uiReducer'
import users from './usersReducer'
import account from './accountReducer'
import roles from './rolesReducer'
import permissions from './permissionsReducer'
import customers from './customersReducer'
import blocks from './blocksReducer'
import shops from './shopsReducer'
import websites from './websitesReducer'
import redirects from './redirectsReducer'
import pages from './pagesReducer'
import products from './productsReducer'
import productColors from './productColorsReducer'
import orders from './ordersReducer'
import countries from './countriesReducer'
import states from './statesReducer'
import templates from './templatesReducer'
import sageWarehouses from './sageWarehouseReducer'
import categories from './categoriesReducer'
import discounts from './discountsReducer'
import vouchers from './vouchersReducer'
import freeForms from './freeFormsReducer'
import specificationGroups from './specificationGroupsReducer'
import specifications from './specificationsReducer'
import articles from './articlesReducer'
import tags from './tagsReducer'
import mediaGroups from './mediaGroupsReducer'

const reducer = () =>
  combineReducers({
    account,
    auth,
    blocks,
    categories,
    customers,
    ui,
    users,
    roles,
    shops,
    orders,
    websites,
    redirects,
    pages,
    permissions,
    products,
    productColors,
    countries,
    states,
    templates,
    sageWarehouses,
    specificationGroups,
    specifications,
    discounts,
    vouchers,
    freeForms,
    articles,
    tags,
    mediaGroups
  })

export default reducer
