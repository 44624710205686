import React from 'react'
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  DatePicker
} from 'antd'
import { fm } from '../../../lang'
import { useSelector } from 'react-redux'

import { MediaGroupFields } from './MediaGroupFields'

export const BasicFields = ({ isLoading, entryId }) => {
  const categories = useSelector((state) => state.categories.entries)
  const products = useSelector((state) => state.products.entries)
  const blocks = useSelector((state) => state.blocks.entries)
  const tags = useSelector((state) => state.tags.entries)

  return (
    <Row gutter={[16, 16]}>
      <Col xs={12}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card
              size={'small'}
              loading={isLoading}
              title={fm('product.basic')}
            >
              <Form.Item
                name="publishedAt"
                label={fm('label.published-at')}
              >
                <DatePicker showTime showNow />
              </Form.Item>
              <Form.Item
                name={['properties', 'showTrademark']}
                valuePropName={'checked'}
              >
                <Checkbox>{'Show trademark symbol'}</Checkbox>
              </Form.Item>
              <Form.Item
                label={fm('label.category')}
                name={'categoryId'}
                hasFeedback
              >
                <Select placeholder="Please select a category">
                  {categories.map((el) => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="tagIds"
                label={fm('label.tags')}
              >
                <Select
                  placeholder="Please select tags"
                  mode={'multiple'}
                >
                  {tags.map((tag) => (
                    <Select.Option key={tag.id} value={tag.id}>
                      {tag.translations.find(({ locale }) => locale === 'en').name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="weight"
                label={'Weight (in kg)'}
                rules={[
                  {
                    required: true,
                    message: 'Please input weight'
                  }
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="trial"
                label={'Trial (in days)'}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="warranty"
                label={'Warranty (in months)'}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="relatedProductIds"
                label={fm('label.related-products')}
              >
                <Select
                  placeholder="Please select related products"
                  mode={'multiple'}
                >
                  {products.map((product) => (
                    product.id !== entryId && (<Select.Option key={product.id} value={product.id}>
                      {product.translations.find(({ locale }) => locale === 'en').name}
                    </Select.Option>)
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={[
                  'properties',
                  'vimeoHowToCode'
                ]}
                label={'How to set Vimeo code'}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Compare block"
                name={['properties', 'compareBlockId']}
              >
                <Select
                  allowClear
                  placeholder="Please select a compare block"
                >
                  {blocks
                    .filter((el) => el.kind === 'compare')
                    .map((el) => (
                      <Select.Option
                        key={el.id}
                        value={el.id}
                      >
                        {el.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              size={'small'}
              loading={isLoading}
              title={fm('product.prices')}
            >
              <Form.Item
                name="priceGbp"
                label={fm('label.price-gbp')}
              >
                <InputNumber
                  min={0}
                  step={0.01}
                  style={{ width: 200 }}
                  precision={2}
                  stringMode
                />
              </Form.Item>
              <Form.Item
                name="priceEur"
                label={fm('label.price-eur')}
              >
                <InputNumber
                  min={0}
                  step={0.01}
                  style={{ width: 200 }}
                  precision={2}
                  stringMode
                />
              </Form.Item>
              <Form.Item
                name="priceUsd"
                label={fm('label.price-usd')}
              >
                <InputNumber
                  min={0}
                  step={0.01}
                  style={{ width: 200 }}
                  precision={2}
                  stringMode
                />
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24}>
            <Card
              loading={isLoading}
              size={'small'}
              title={'Refurbished product'}
            >
              <Form.Item
                name={['properties', 'refurbished']}
                valuePropName={'checked'}
              >
                <Checkbox>
                  {'This is refurbished version'}
                </Checkbox>
              </Form.Item>
              <Form.Item
                label={'Linked product'}
                name={['properties', 'productLinkedId']}
                hasFeedback
              >
                <Select placeholder="Please select a product" allowClear>
                  {products.map((el) => (
                    <Select.Option key={el.id} value={el.id}>
                      {el.translations.find(({ locale }) => locale === 'en').name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={['properties', 'linkedContent']}
                valuePropName={'checked'}
              >
                <Checkbox>
                  {'Take content from linked product'}
                </Checkbox>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={12}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card
              size={'small'}
              loading={isLoading}
              title={'Media content'}
            >
              <MediaGroupFields holderType="Product" />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
