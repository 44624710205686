import * as types from '../../constants/actionTypes/accountActionTypes'
import { getToken } from '../../helpers/auth'

export const updateAccount = (dispatch, params, push) => {
  dispatch.sync({
    type: types.ACCOUNT_UPDATE,
    account: params
  })
}

export const setAccount = (params) => {
  const savedUserData = getToken()
  const { userId } = savedUserData
  localStorage.setItem(
    `${process.env.NODE_ENV}.lumie.account.${userId}`,
    JSON.stringify(params)
  )
}

export const resetAccount = () => {
  const savedUserData = getToken()
  const { userId } = savedUserData
  localStorage.removeItem(`${process.env.NODE_ENV}.lumie.account.${userId}`)
}

export const getAccount = () => {
  const savedUserData = getToken()
  if (savedUserData) {
    const { userId } = savedUserData
    const token = localStorage.getItem(
      `${process.env.NODE_ENV}.lumie.account.${userId}`
    )
    return token ? JSON.parse(token) : undefined
  }
}
