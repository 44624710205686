import * as types from '../../constants/actionTypes/sageWarehousesActionTypes'
const defaultState = {
  entries: []
}

export default function sageWarehouseReducer(state = defaultState, action) {
  switch (action.type) {
    case types.SAGE_WAREHOUSES_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
