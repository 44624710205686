import * as types from '../../constants/actionTypes/websitesActionTypes'

export const createWebsite = (dispatch, params) => {
  return dispatch.sync({
    type: types.WEBSITES_CREATE,
    website: params
  })
}

export const updateWebsite = (dispatch, params) => {
  return dispatch.sync({
    type: types.WEBSITES_UPDATE,
    website: params
  })
}

export const deleteWebsite = (dispatch, id, push) => {
  push('/content/websites')
  dispatch.sync({
    type: types.WEBSITES_DELETE,
    website: { id }
  })
}

export const selectWebsite = (dispatch, id) => {
  dispatch.local({
    type: types.WEBSITE_SELECT,
    id
  })
}

export const resetWebsite = (dispatch, id) => {
  dispatch.local({
    type: types.WEBSITE_FORM_RESET,
    id
  })
}
