import React from 'react'
import { TreeSelect } from 'antd'
import _ from 'lodash'
import { useSelector } from 'react-redux'

export const ProductSelect = ({ ...attributes }) => {
  const categories = useSelector((state) => state.categories.entries)
  const products = useSelector((state) => state.products.entries)

  const productsIndex = _.groupBy(products, 'categoryId')
  const productsTree = _.reject(categories, ({ id }) => _.isEmpty(productsIndex[id])).map((category) => ({
    title: `${category.translations.find(({ locale }) => locale === 'en').title} (all products)`,
    value: [category.id, ..._.map(productsIndex[category.id], 'id')].join(','),
    children: _.map(productsIndex[category.id], (product) => ({
      title: product.translations.find(({ locale }) => locale === 'en').name,
      value: product.id
    }))
  }))

  return (
    <TreeSelect
      placeholder="Please select products"
      treeCheckable={true}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      treeData={productsTree}
      filterTreeNode={(input, treeNode) =>
        treeNode.title.toLowerCase().includes(input.toLowerCase())
      }
      autoClearSearchValue={false}
      {...attributes}
    />
  )
}
