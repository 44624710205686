import { fm } from '../lang'

const breadcrumbNameMap = {
  '/account': { text: fm('breadcrumbs.account'), noneSelect: true },
  '/settings': { text: fm('breadcrumbs.settings'), noneSelect: true },
  '/settings/users': { text: fm('breadcrumbs.users') },
  '/settings/users/new': { text: fm('breadcrumbs.new') },
  '/settings/users/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/settings/roles': { text: fm('breadcrumbs.roles') },
  '/settings/roles/new': { text: fm('breadcrumbs.new') },
  '/settings/roles/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/settings/shops': { text: fm('breadcrumbs.shops') },
  '/settings/shops/new': { text: fm('breadcrumbs.new') },
  '/settings/shops/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/customers': { text: fm('breadcrumbs.customers') },
  '/customers/new': { text: fm('breadcrumbs.new') },
  '/customers/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/content': { text: fm('breadcrumbs.content'), noneSelect: true },
  '/content/websites': { text: fm('breadcrumbs.websites') },
  '/content/websites/new': { text: fm('breadcrumbs.new') },
  '/content/websites/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/content/redirects': { text: fm('breadcrumbs.redirects') },
  '/content/redirects/new': { text: fm('breadcrumbs.new') },
  '/content/redirects/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/content/pages': { text: fm('breadcrumbs.pages') },
  '/content/pages/new': { text: fm('breadcrumbs.new') },
  '/content/pages/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/content/pages/:id/blocks': { text: fm('breadcrumbs.blocks') },
  '/content/blocks': { text: fm('breadcrumbs.blocks') },
  '/content/blocks/new': { text: fm('breadcrumbs.new') },
  '/content/blocks/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/content/articles': { text: fm('breadcrumbs.articles') },
  '/content/articles/new': { text: fm('breadcrumbs.new') },
  '/content/articles/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/content/tags': { text: fm('breadcrumbs.tags') },
  '/content/tags/new': { text: fm('breadcrumbs.new') },
  '/content/tags/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/assortment': { text: fm('breadcrumbs.assortment'), noneSelect: true },
  '/assortment/products': { text: fm('breadcrumbs.products') },
  '/assortment/products/new': { text: fm('breadcrumbs.new') },
  '/assortment/products/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/assortment/products/:id/colors': { text: fm('breadcrumbs.colors'), match: ['id'] },
  '/assortment/products/:id/colors/:colorId': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/assortment/categories': { text: fm('breadcrumbs.categories') },
  '/assortment/categories/new': { text: fm('breadcrumbs.new') },
  '/assortment/categories/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/assortment/specifications': { text: fm('breadcrumbs.specifications') },
  '/assortment/specifications/new': { text: fm('breadcrumbs.new') },
  '/assortment/specifications/:id': {
    text: fm('breadcrumbs.edit'),
    match: ['id']
  },
  '/assortment/specification-groups': {
    text: fm('breadcrumbs.specification-groups')
  },
  '/assortment/specification-groups/new': { text: fm('breadcrumbs.new') },
  '/assortment/specification-groups/:id': {
    text: fm('breadcrumbs.edit'),
    match: ['id']
  },
  '/orders': { text: fm('breadcrumbs.orders') },
  '/orders/new': { text: fm('breadcrumbs.new') },
  '/orders/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/discounts': { text: fm('breadcrumbs.discounts') },
  '/discounts/new': { text: fm('breadcrumbs.new') },
  '/discounts/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/vouchers': { text: fm('breadcrumbs.vouchers') },
  '/vouchers/new': { text: fm('breadcrumbs.new') },
  '/vouchers/:id': { text: fm('breadcrumbs.edit'), match: ['id'] },
  '/free_forms': { text: fm('breadcrumbs.free-forms') },
  '/free_forms/new': { text: fm('breadcrumbs.new') },
  '/free_forms/:id': { text: fm('breadcrumbs.edit'), match: ['id'] }
}

export default breadcrumbNameMap
