import { createSelector } from 'reselect'

const getVouchers = (state) => state.vouchers.entries
const getFilters = (state) => state.vouchers.tableFilters

export const getFilteredVouchers = createSelector(
  getVouchers,
  getFilters,
  (vouchers, filters) => {
    if (vouchers.length === 0) return []
    let result = vouchers
    if (filters.term && filters.term !== '') {
      result = result.filter((v) =>
        (v.name && v.name.toLowerCase().includes(filters.term.toLowerCase())) ||
          (v.code && v.code.toLowerCase().includes(filters.term.toLowerCase()))
      )
    }

    return result
  }
)
