import React, { useCallback, useState } from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { MenuOutlined, PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import _ from 'lodash'
import { arrayMoveImmutable } from 'array-move'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import { updateSpecificationPosition } from '../../../data/actions/specifications'

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)
const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

export const SpecificationsTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const dispatch = useDispatch()
  const entries = useSelector((state) => state.specifications.entries)
  const [dataSource, setDataSource] = useState(_.orderBy(entries, 'position'))
  const handleClickAdd = () => {
    push('/assortment/specifications/new')
  }
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/assortment/specifications/${recordId}`)
    },
    [push, dispatch]
  )
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource, oldIndex, newIndex)
        .filter((el) => !!el)
        .map((el, index) => {
          return { ...el, position: index }
        })
      setDataSource(newData)
      updateSpecificationPosition(dispatch, newData)
    }
  }

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'drag-visible',
      render: (name, record) => (
        <a onClick={() => handleClickRow(record.id)}>{name}</a>
      )
    }
  ]
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = entries.findIndex((x) => x.id === restProps['data-row-key'])
    return <SortableItem index={index} {...restProps} />
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('specifications.title')}
      extra={
        allow('can_create_specification') && (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size={'small'}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={dataSource}
          columns={columns}
          size="small"
          loading={isLoading}
          pagination={false}
          rowKey={'id'}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
      </Card>
    </PageHeader>
  )
}
