import * as types from '../../constants/actionTypes/categoriesActionTypes'
import { v4 as uuidv4 } from 'uuid'

export const emptyTranslation = {
  locale: null,
  slug: null,
  title: null,
  details: null
}

const emptyEntry = {
  id: null,
  name: '',
  trial: 0,
  warranty: 0,
  liveStock: false,
  googleProductCategory: null,
  translations: [{ ...emptyTranslation, locale: 'en' }]
}

const defaultState = {
  entry: { ...emptyEntry, id: uuidv4() },
  entries: []
}

export default function categoriesReducer(state = defaultState, action) {
  switch (action.type) {
    case types.CATEGORIES_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.category.id) {
            return { ...action.category }
          } else {
            return u
          }
        })
      }
    case types.CATEGORIES_DELETE:
      return {
        ...state,
        entry: { ...emptyEntry, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.category.id)
      }
    case types.CATEGORY_FETCH:
      return { ...state, entry: action.data }
    case types.CATEGORIES_CREATE:
      return {
        ...state,
        entries: [{ ...action.category }, ...state.entries]
      }
    case types.CATEGORY_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.CATEGORY_FORM_RESET:
      return { ...state, entry: { ...emptyEntry, id: uuidv4() } }
    case types.CATEGORIES_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
