import React from 'react'
import { Row, Col, Form, Input, Select } from 'antd'
import { onKeyDownForm } from '../../../../helpers/form'
import { useSelector } from 'react-redux'
import { FormActionBox } from '../../../Shared/FormActionBox'
import { useRecordHook } from '../../../../helpers/hooks/useRecordHook'
import { fm } from '../../../../lang'
import { getBlocksTags } from '../../../../data/selectors/blocks'
const { Option } = Select

export const ArticlesBlock = ({ form, onFinish, onDelete, isLoading }) => {
  const { newRecord } = useRecordHook()
  const blockTags = useSelector(getBlocksTags)
  const tags = []
  blockTags.forEach((tag) => {
    tags.push(<Option key={tag}>{tag}</Option>)
  })
  const articles = useSelector((state) => state.articles.entries)
  return (
    <Row>
      <Col xs={24}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          onKeyDown={onKeyDownForm}
        >
          <Form.Item
            name="name"
            label={fm('label.name')}
            rules={[
              {
                required: true,
                message: 'Please input name'
              }
            ]}
          >
            <Input style={{ width: 400 }} />
          </Form.Item>
          <Form.Item name="tags" label={fm('label.tags')}>
            <Select mode="tags" style={{ width: 400 }}>
              {tags}
            </Select>
          </Form.Item>
          <Form.Item
            name={['properties', 'articles']}
            label={fm('label.articles')}
          >
            <Select
              mode="tags"
              style={{ width: 400 }}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {articles.map((article) => {
                return (
                  <Option key={article.id}>
                    {article.translations.find(({ locale }) => locale === 'en').title}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <FormActionBox
            isLoading={isLoading}
            newRecord={newRecord}
            onDelete={onDelete}
          />
        </Form>
      </Col>
    </Row>
  )
}
