import * as types from '../../constants/actionTypes/shopsActionTypes'

export const createShop = (dispatch, params) => {
  return dispatch.sync({
    type: types.SHOPS_CREATE,
    shop: params
  })
}

export const updateShop = (dispatch, params) => {
  return dispatch.sync({
    type: types.SHOPS_UPDATE,
    shop: params
  })
}

export const deleteShop = (dispatch, id, push) => {
  push('/settings/shops')
  dispatch.sync({
    type: types.SHOPS_DELETE,
    shop: { id }
  })
}

export const selectShop = (dispatch, id) => {
  dispatch.local({
    type: types.SHOP_SELECT,
    id
  })
}

export const resetShop = (dispatch, id) => {
  dispatch.local({
    type: types.SHOP_FORM_RESET,
    id
  })
}
