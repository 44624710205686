import React, { useCallback, useEffect, useState } from 'react'
import { Card, Row, Col, Form, Input, PageHeader, notification } from 'antd'
import { css } from 'linaria'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createRedirect,
  deleteRedirect,
  resetRedirect,
  selectRedirect,
  updateRedirect
} from '../../../data/actions/redirects'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../../helpers/form'

const formWrapper = css`
  .ant-card-body {
    padding: 16px;
  }
`

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const RedirectForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.redirects)
  const system = entry ? entry.system : false
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/content/redirects')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetRedirect(dispatch)
        handleBack()
      } else {
        form.setFieldsValue(entry)
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, handleBack, isLoading, formValues])
  const onFinish = useCallback(async() => {
    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values }
        await createRedirect(dispatch, createParams)
        push(`/content/redirects/${createParams.id}`)
        notification.success({ message: 'Redirect successfully created' })
      } else {
        const updateParams = { id, ...values }
        await updateRedirect(dispatch, updateParams)
        notification.success({ message: 'Redirect successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [newRecord, id, dispatch, entry, form, push])
  const deleteRecord = useCallback(() => {
    deleteRedirect(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectRedirect(dispatch, id)
    } else if (newRecord) {
      resetRedirect(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
      <PageHeader
          breadcrumb={breadcrumb}
          title={fm(`redirect.form.${cardTitle}`)}
          onBack={handleBack}
      >
        <Row>
          <Col xs={24}>
            <Row gutter={[0, 16]}>
              <Col xs={24}>
                <Form
                    form={form}
                    layout={'vertical'}
                    className={formClass}
                    onKeyDown={onKeyDownForm}
                    onFinish={onFinish}
                >
                  <Row gutter={16}>
                    <Col xs={12}>
                      <Card
                          size={'small'}
                          className={formWrapper}
                          loading={isLoading}
                      >
                        <Form.Item
                            name="pattern"
                            label={fm('label.pattern')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input title'
                              }
                            ]}
                        >
                          <Input disabled={system} readOnly={system} />
                        </Form.Item>
                        <Form.Item
                            name="location"
                            label={fm('label.location')}
                            rules={[
                              {
                                required: true,
                                message: 'Please input location'
                              }
                            ]}
                        >
                          <Input disabled={system} readOnly={system} />
                        </Form.Item>
                      </Card>
                    </Col>
                  </Row>
                  {!system && (
                    <FormActionBox
                      isLoading={isLoading}
                      newRecord={newRecord}
                      onDelete={deleteRecord}
                    />
                  )}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHeader>
  )
}
