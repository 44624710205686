import React from 'react'
import { Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { fm } from '../../lang'
import { useDispatch } from 'react-redux'
import { signIn } from '../../data/actions/auth'

export const LoginForm = () => {
  const dispatch = useDispatch()
  const onFinish = (values) => {
    signIn(dispatch, values.email, values.password)
  }

  return (
    <Form name="normal_login" onFinish={onFinish} wrapperCol={{ span: 24 }}>
      <Form.Item
        name="email"
        rules={[{ required: true, message: fm('validate.error.email') }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: fm('validate.error.password') }]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          {fm('button.log-in')}
        </Button>
      </Form.Item>
    </Form>
  )
}
