import * as types from '../../constants/actionTypes/usersActionTypes'

export const updateUser = (dispatch, params, push) => {
  push('/settings/users')
  dispatch.sync({
    type: types.USERS_UPDATE,
    user: params
  })
}

export const deleteUser = (dispatch, id, push) => {
  push('/settings/users')
  dispatch.sync({
    type: types.USERS_DELETE,
    user: { id }
  })
}

export const createUser = (dispatch, params, push) => {
  dispatch.sync({
    type: types.USERS_CREATE,
    user: params
  })
  push('/settings/users')
}

export const selectUser = (dispatch, id) => {
  dispatch.local({
    type: types.USER_SELECT,
    id
  })
}

export const resetUser = (dispatch, id) => {
  dispatch.local({
    type: types.USER_FORM_RESET,
    id
  })
}
