import * as types from '../../constants/actionTypes/blocksActionTypes'
import { v4 as uuidv4 } from 'uuid'
import { nanoid } from 'nanoid'

export const kinds = ['html', 'collection', 'editor', 'articles', 'compare']

export const emptyComponentTranslation = {
  locale: null,
  title: '',
  features: '',
  firstColumnText: '',
  secondColumnText: ''
}

export const emptyComponent = {
  id: null,
  name: '',
  type: '',
  productIds: [],
  collectionStyle: 'first',
  productId: null,
  blockId: null,
  viewType: 'first',
  textContent: [{ ...emptyComponentTranslation, locale: 'en' }]
}

export const emptyTranslation = {
  locale: null,
  editor: {}
}

const emptyBlock = {
  id: null,
  name: '',
  code: '',
  style: '',
  key: nanoid(),
  files: [],
  url: [],
  tags: [],
  kind: null,
  category_id: null,
  properties: {
    parentNodeClass: '',
    articles: [],
    tags: []
  },
  translations: [{ ...emptyTranslation, locale: 'en' }]
}

const emptyFilters = {
  term: null,
  kind: null,
  tags: []
}

const defaultState = {
  entry: { ...emptyBlock, id: uuidv4() },
  entries: [],
  tableFilters: emptyFilters
}

export default function blocksReducer(state = defaultState, action) {
  switch (action.type) {
    case types.BLOCKS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((b) => {
          if (b.id === action.block.id) {
            return { ...action.block }
          } else {
            return b
          }
        })
      }
    case types.BLOCK_UPDATED:
      return {
        ...state,
        entries: state.entries.map((b) => b.id === action.data.id ? { ...b, ...action.data } : b),
        entry: state.entry.id === action.data.id ? { ...state.entry, ...action.data } : state.entry
      }
    case types.BLOCKS_UPDATE_LOCAL:
      return {
        ...state,
        entries: state.entries.map((b) => b.id === action.id ? { ...b, ...action.data } : b),
        entry: { ...state.entry, ...action.data }
      }
    case types.BLOCKS_DELETE:
      return {
        ...state,
        entry: { ...emptyBlock, id: uuidv4() },
        entries: state.entries.filter((b) => b.id !== action.block.id)
      }
    case types.BLOCK_FETCH:
      return { ...state, entry: action.data }
    case types.BLOCKS_CREATE:
      return {
        ...state,
        entries: [{ ...action.block }, ...state.entries]
      }
    case types.BLOCK_SELECT:
      return { ...state, entry: state.entries.find((b) => b.id === action.id) }
    case types.BLOCK_FORM_RESET:
      return { ...state, entry: { ...emptyBlock, id: uuidv4() } }
    case types.BLOCKS_FETCH:
      return { ...state, entries: action.data }
    case types.BLOCK_CREATED:
      return {
        ...state,
        entries: state.entries.map((b) => {
          if (b.id === action.data.id) {
            return { ...b, ...action.data }
          } else {
            return b
          }
        }),
        entry:
          state.entry.id === action.data.id
            ? { ...state.entry, ...action.data }
            : state.entry
      }
    case types.BLOCKS_CHANGE_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.filterType]: action.filterValue
        }
      }
    case types.BLOCKS_CLEAR_TABLE_FILTERS:
      return { ...state, tableFilters: emptyFilters }
    case types.BLOCK_REMOVE_FILE:
      return {
        ...state,
        entries: state.entries.map((b) => {
          if (b.id === action.id) {
            return {
              ...b,
              files: b.files.filter((f) => f.blobId !== action.blobId)
            }
          } else {
            return b
          }
        }),
        entry:
          state.entry.id === action.id
            ? {
                ...state.entry,
                files: state.entry.files.filter(
                  (f) => f.blobId !== action.blobId
                )
              }
            : state.entry
      }
    default:
      return state
  }
}
