import * as types from '../../constants/actionTypes/mediaGroupsActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyMediaGroup = {
  id: null,
  name: null,
  key: null,
  holder_type: null,
  collection: false
}

const defaultState = {
  entry: { ...emptyMediaGroup, id: uuidv4() },
  entries: []
}

export default function mediaGroupsReducer(state = defaultState, action) {
  switch (action.type) {
    case types.MEDIA_GROUPS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.mediaGroup.id) {
            return { ...action.mediaGroup }
          } else {
            return u
          }
        })
      }
    case types.MEDIA_GROUPS_DELETE:
      return {
        ...state,
        entry: { ...emptyMediaGroup, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.mediaGroup.id)
      }
    case types.MEDIA_GROUP_FETCH:
      return { ...state, entry: action.data }
    case types.MEDIA_GROUPS_CREATE:
      return {
        ...state,
        entries: [{ ...action.mediaGroup }, ...state.entries]
      }
    case types.MEDIA_GROUP_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.MEDIA_GROUP_FORM_RESET:
      return { ...state, entry: { ...emptyMediaGroup, id: uuidv4() } }
    case types.MEDIA_GROUPS_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
