import React from 'react'
import { Form, Input, Row, Col } from 'antd'

import { fm } from '../../../lang'
import { CountriesSelect } from './CountriesSelect'

export const AddressFields = ({ prefix }) => {
  return (
    <Row gutter={[16, 0]}>
      <Col xs={24}>
        <Form.Item
          name={prefix ? [prefix, 'fullName'] : 'fullName'}
          label={fm('label.full-name')}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name={prefix ? [prefix, 'companyName'] : 'companyName'}
          label={fm('label.company')}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name={prefix ? [prefix, 'address'] : 'address'}
          label={fm('label.address')}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name={prefix ? [prefix, 'address2'] : 'address2'}
          label={fm('label.address2')}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name={prefix ? [prefix, 'city'] : 'city'}
          label={fm('label.city')}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name={prefix ? [prefix, 'stateName'] : 'stateName'}
          label={fm('label.stateName')}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={12}>
        <CountriesSelect prefix={prefix} />
      </Col>
      <Col xs={12}>
        <Form.Item
          name={prefix ? [prefix, 'postcode'] : 'postcode'}
          label={fm('label.postal-code')}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name={prefix ? [prefix, 'phoneNumber'] : 'phoneNumber'}
          label={fm('label.phone')}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}

AddressFields.defaultProps = {
  prefix: ''
}
