import * as types from '../../constants/actionTypes/rolesActionTypes'

export const updateRole = (dispatch, params, push) => {
  push('/settings/roles')
  dispatch.sync({
    type: types.ROLES_UPDATE,
    role: params
  })
}

export const deleteRole = (dispatch, id, push) => {
  push('/settings/roles')
  dispatch.sync({
    type: types.ROLES_DELETE,
    role: { id }
  })
}

export const createRole = (dispatch, params, push) => {
  dispatch.sync({
    type: types.ROLES_CREATE,
    role: params
  })
  push('/settings/roles')
}

export const selectRole = (dispatch, id) => {
  dispatch.local({
    type: types.ROLE_SELECT,
    id
  })
}

export const resetRole = (dispatch, id) => {
  dispatch.local({
    type: types.ROLE_FORM_RESET,
    id
  })
}
