import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'

import { useAllow } from '../helpers/hooks/usePermissions'
import { CustomersScreen } from '../screens/Customers/CustomersScreen'
import { PrivateRoute } from './PrivateRoute'
import { CustomerFormScreen } from '../screens/Customers/CustomerFormScreen'
import { useSelector } from 'react-redux'

export const CustomersRoute = ({ isAuthenticated }) => {
  const isLoading = false
  const allow = useAllow()
  const filters = useSelector((state) => state.customers.filtersEntries)
  const { pagination, term } = filters
  const isLoadingCustomers = useSubscription([
    {
      channel: 'customers',
      current: pagination.current,
      pageSize: pagination.pageSize,
      term
    }
  ])
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_customer')}
        path="/customers/new"
        exact
        isAuthenticated={isAuthenticated}
        component={CustomerFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_customer')}
        path="/customers/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={CustomerFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_customers')}
        path="/customers"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading: isLoadingCustomers }}
        component={CustomersScreen}
      />
    </Switch>
  )
}
