import React, { useEffect, useState } from 'react'
import { Table, Card, Row, Col, Button } from 'antd'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import { arrayMoveImmutable } from 'array-move'
import { ModalForm } from './ModalForm'
import { emptyComponent } from '../../../../data/reducers/blocksReducer'

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)
export const ComponentsTable = ({
  components,
  saveComponent,
  updateComponents,
  removeComponent
}) => {
  const [component, setComponent] = useState(emptyComponent)
  const [modalOpen, setModalOpen] = useState(false)
  const [dataSource, setDataSource] = useState(components)
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 30,
      className: 'drag-visible'
    },
    {
      title: 'Actions',
      width: 100,
      className: 'drag-hidden',
      render: (record) => {
        return (
          <>
            <Button
              size={'small'}
              type={'ghost'}
              onClick={() => editComponent(record.id)}
            >
              Edit
            </Button>
            <Button
              style={{ marginLeft: 16 }}
              size={'small'}
              type={'danger'}
              onClick={() => remove(record.id)}
            >
              Remove
            </Button>
          </>
        )
      }
    }
  ]
  useEffect(() => {
    setDataSource(components)
  }, [components])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource, oldIndex, newIndex)
        .filter((el) => !!el)
        .map((el, index) => {
          return { ...el, index }
        })
      setDataSource(newData)
      updateComponents(newData)
    }
  }

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.id === restProps['data-row-key']
    )
    return <SortableItem index={index} {...restProps} />
  }

  const editComponent = (id) => {
    setComponent(components.find((el) => el.id === id))
    setModalOpen(true)
  }

  const addNewComponent = (e) => {
    setComponent({ ...emptyComponent })
    setModalOpen(true)
  }

  const remove = (id) => {
    setDataSource(dataSource.filter((el) => el.id !== id))
    removeComponent(id)
  }

  return (
    <Card size={'small'} title={'Components'}>
      <Row gutter={[16, 16]}>
        <Button type="primary" onClick={addNewComponent}>
          Add a component
        </Button>
        <ModalForm
          saveComponent={saveComponent}
          component={component}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
        <Col xs={24}>
          <Table
            size={'small'}
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }}
          />
        </Col>
      </Row>
    </Card>
  )
}
