import * as types from '../../constants/actionTypes/pagesActionTypes'
import { arrayMoveImmutable } from 'array-move'

export const createPage = (dispatch, params) => {
  return dispatch.sync({
    type: types.PAGES_CREATE,
    page: params
  })
}

export const updatePage = (dispatch, params) => {
  return dispatch.sync({
    type: types.PAGES_UPDATE,
    page: params
  })
}

export const deletePage = (dispatch, id, push) => {
  push('/content/pages')
  dispatch.sync({
    type: types.PAGES_DELETE,
    page: { id }
  })
}

export const selectPage = (dispatch, id) => {
  dispatch.local({
    type: types.PAGE_SELECT,
    id
  })
}

export const resetPage = (dispatch, id) => {
  dispatch.local({
    type: types.PAGE_FORM_RESET,
    id
  })
}

export const changePagesTableFilters = (dispatch, filterType, filterValue) => {
  dispatch.local({
    type: types.PAGES_CHANGE_TABLE_FILTER,
    filterType,
    filterValue
  })
}

export const removeBlock = (blocks, id) => {
  const filteredBlocks = blocks.filter((b) => !b._destroy)
  const deletedBlocks = blocks.filter((b) => b._destroy)
  const currentIndex = filteredBlocks.findIndex((b) => b.id === id)
  const newBlocks = arrayMoveImmutable(filteredBlocks, currentIndex, -1)
  const deletedBlock = newBlocks[newBlocks.length - 1]
  return newBlocks
    .slice(0, -1)
    .concat([...deletedBlocks, { ...deletedBlock, _destroy: true }])
}

export const fetchTranslate = (dispatch) => {
  dispatch.sync({
    type: types.PAGES_FETCH_TRANSLATE
  })
}

export const updatePageLocal = (dispatch, valueType, value, id) => {
  dispatch.local({
    type: types.PAGES_UPDATE_LOCAL,
    valueType,
    value,
    id
  })
}
