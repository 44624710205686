import React, { useCallback } from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'

const columns = [
  {
    title: fm('table.name'),
    key: 'name',
    render: (value, record) => {
      return `${record.firstName} ${record.lastName}`
    }
  },
  {
    title: fm('users.table.email'),
    dataIndex: 'email',
    key: 'email'
  }
]

export const UsersTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const dispatch = useDispatch()
  const { entries } = useSelector((state) => state.users)
  const handleClickAdd = () => {
    push('/settings/users/new')
  }
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/settings/users/${recordId}`)
    },
    [push, dispatch]
  )
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('users.title')}
      extra={
        allow('can_create_user') && (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size={'small'}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey={'id'}
        />
      </Card>
    </PageHeader>
  )
}
