import * as types from '../../constants/actionTypes/discountsActionTypes'

export const createDiscount = (dispatch, params) => {
  return dispatch.sync({
    type: types.DISCOUNTS_CREATE,
    discount: params
  })
}

export const updateDiscount = (dispatch, params) => {
  return dispatch.sync({
    type: types.DISCOUNTS_UPDATE,
    discount: params
  })
}

export const deleteDiscount = (dispatch, id, push) => {
  push('/discounts')
  dispatch.sync({
    type: types.DISCOUNTS_DELETE,
    discount: { id }
  })
}

export const selectDiscount = (dispatch, id) => {
  dispatch.local({
    type: types.DISCOUNT_SELECT,
    id
  })
}

export const resetDiscount = (dispatch, id) => {
  dispatch.local({
    type: types.DISCOUNT_FORM_RESET,
    id
  })
}
