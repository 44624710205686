import * as types from '../../constants/actionTypes/freeFormsActionTypes'

export const createFreeForm = (dispatch, params) => {
  return dispatch.sync({
    type: types.FREE_FORMS_CREATE,
    freeForm: params
  })
}

export const updateFreeForm = (dispatch, params) => {
  return dispatch.sync({
    type: types.FREE_FORMS_UPDATE,
    freeForm: params
  })
}

export const deleteFreeForm = (dispatch, id, push) => {
  push('/freeForms')
  dispatch.sync({
    type: types.FREE_FORMS_DELETE,
    freeForm: { id }
  })
}

export const selectFreeForm = (dispatch, id) => {
  dispatch.local({
    type: types.FREE_FORM_SELECT,
    id
  })
}

export const resetFreeForm = (dispatch, id) => {
  dispatch.local({
    type: types.FREE_FORM_FORM_RESET,
    id
  })
}
