import * as types from '../../constants/actionTypes/articlesActionTypes'

export const changeArticlesTableFilters = (dispatch, filterType, filterValue) => {
  dispatch.local({
    type: types.ARTICLES_CHANGE_TABLE_FILTER,
    filterType,
    filterValue
  })
}

export const createArticle = (dispatch, params) => {
  return dispatch.sync({
    type: types.ARTICLES_CREATE,
    article: params
  })
}

export const updateArticle = (dispatch, params) => {
  return dispatch.sync({
    type: types.ARTICLES_UPDATE,
    article: params
  })
}

export const deleteArticle = (dispatch, id, push) => {
  push('/content/articles')
  dispatch.sync({
    type: types.ARTICLES_DELETE,
    article: { id }
  })
}

export const selectArticle = (dispatch, id) => {
  dispatch.local({
    type: types.ARTICLE_SELECT,
    id
  })
}

export const resetArticle = (dispatch, id) => {
  dispatch.local({
    type: types.ARTICLE_FORM_RESET,
    id
  })
}
