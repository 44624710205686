import React, { useCallback, useEffect } from 'react'
import { Row, Col, Form, PageHeader } from 'antd'
import { css } from 'linaria'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { fm } from '../../lang'
import {
  createCustomer,
  deleteCustomer,
  resetCustomer,
  updateCustomer
} from '../../data/actions/customers'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../Shared/FormActionBox'
import { usePush } from '../../data/actions/routes'
import { BasicFields } from './Form/BasicFileds'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { AddressesTable } from './Form/AddressesTable'
import { onKeyDownForm } from '../../helpers/form'
import { useSubscription } from '@logux/redux'

const formClass = css`
  width: 100%;
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

export const CustomerForm = ({ path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.customers)
  const { isSelected } = entry
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/customers')
  }, [push])
  const isLoading = useSubscription([`customers/${id || 'new'}`])
  const loading = !isSelected && isLoading
  useEffect(() => {
    if (!loading) {
      if (entry.notFound) {
        handleBack()
      } else {
        form.setFieldsValue(entry)
      }
    }
  }, [entry, handleBack])
  const onFinish = useCallback(() => {
    const values = form.getFieldsValue(true)
    if (newRecord) {
      createCustomer(dispatch, { ...entry, ...values }, push)
    } else {
      updateCustomer(dispatch, { id, ...values }, push)
    }
  }, [newRecord, id, dispatch])
  const deleteRecord = useCallback(() => {
    deleteCustomer(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (newRecord) {
      resetCustomer(dispatch)
    }
  }, [dispatch, resetCustomer])
  const cardTitle = newRecord ? 'new' : 'edit'
  const createAddress = (params, kind) => {
    const oldItems = form.getFieldValue(kind)
    const kindValue = kind === 'deliveryAddresses' ? 'delivery' : 'payment'
    const newItems = [
      ...oldItems,
      { ...params, id: uuidv4(), kind: kindValue, newRecord: false }
    ]
    form.setFieldsValue({ [kind]: newItems })
  }
  const deleteAddress = (id, kind) => {
    const oldItems = form.getFieldValue(kind)
    const newItems = oldItems.map((el) => {
      if (el.id === id) {
        return { ...el, _destroy: true }
      } else {
        return el
      }
    })
    form.setFieldsValue({ deliveryAddresses: newItems })
  }
  const updateAddress = (params, kind) => {
    const oldItems = form.getFieldValue(kind)
    const newItems = oldItems.map((el) => {
      if (el.id === params.id) {
        return { ...el, ...params }
      } else {
        return el
      }
    })
    form.setFieldsValue({ deliveryAddresses: newItems })
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`customer.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Form
            initialValues={{ deliveryAddresses: [], paymentAddresses: [] }}
            form={form}
            layout={'vertical'}
            className={formClass}
            onFinish={onFinish}
            onKeyDown={onKeyDownForm}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Row gutter={[16, 16]}>
                  <Col xs={12}>
                    <BasicFields isLoading={loading} />
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <Form.Item shouldUpdate>
                  {(f) => (
                    <AddressesTable
                      isLoading={loading}
                      createAddress={createAddress}
                      deleteAddress={deleteAddress}
                      updateAddress={updateAddress}
                      kind={'deliveryAddresses'}
                      items={f.getFieldValue('deliveryAddresses')}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item shouldUpdate>
                  {(f) => (
                    <AddressesTable
                      isLoading={loading}
                      createAddress={createAddress}
                      deleteAddress={deleteAddress}
                      updateAddress={updateAddress}
                      kind={'paymentAddresses'}
                      items={f.getFieldValue('paymentAddresses')}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <FormActionBox
              isLoading={loading}
              newRecord={newRecord}
              onDelete={deleteRecord}
            />
          </Form>
        </Col>
      </Row>
    </PageHeader>
  )
}
