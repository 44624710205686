import * as types from '../../constants/actionTypes/blocksActionTypes'
import { PAGES_AFTER_DESTROY_BLOCK } from '../../constants/actionTypes/pagesActionTypes'

export const createBlock = (dispatch, params) => {
  return dispatch.sync({
    type: types.BLOCKS_CREATE,
    block: params
  })
}

export const updateBlock = (dispatch, params) => {
  return dispatch.sync({
    type: types.BLOCKS_UPDATE,
    block: params
  })
}

export const updateBlockLocal = (dispatch, id, params) => {
  dispatch.local({
    type: types.BLOCKS_UPDATE_LOCAL,
    id,
    data: params
  })
}

export const removeFile = (dispatch, id, blobId) => {
  dispatch.local({
    type: types.BLOCK_REMOVE_FILE,
    id,
    blobId
  })
}

export const deleteBlock = (dispatch, id, push) => {
  push('/content/blocks')
  dispatch.sync({
    type: types.BLOCKS_DELETE,
    block: { id }
  })
  dispatch.crossTab({
    type: PAGES_AFTER_DESTROY_BLOCK,
    id
  })
}

export const selectBlock = (dispatch, id) => {
  dispatch.local({
    type: types.BLOCK_SELECT,
    id
  })
}

export const resetBlock = (dispatch, id) => {
  dispatch.local({
    type: types.BLOCK_FORM_RESET,
    id
  })
}

export const changeBlocksTableFilters = (dispatch, filterType, filterValue) => {
  dispatch.local({
    type: types.BLOCKS_CHANGE_TABLE_FILTER,
    filterType,
    filterValue
  })
}

export const clearBlocksTableFilters = (dispatch) => {
  dispatch.local({
    type: types.BLOCKS_CLEAR_TABLE_FILTERS
  })
}
