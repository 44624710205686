import React from 'react'
import _ from 'lodash'
import { Menu, Layout } from 'antd'
import { css } from 'linaria'
import {
  UserOutlined,
  DashboardOutlined,
  SettingOutlined,
  CodeSandboxOutlined,
  GroupOutlined,
  BarcodeOutlined,
  ShoppingOutlined,
  PercentageOutlined,
  FormOutlined,
  BorderInnerOutlined,
  DisconnectOutlined,
  SlidersOutlined
} from '@ant-design/icons'
import { fm } from '../../lang'
import { usePush } from '../../data/actions/routes'
import { useMenu } from '../../helpers/hooks/useMenu'
import { useAllow } from '../../helpers/hooks/usePermissions'

const sidebarWrapper = css`
  height: 100%;
  //box-shadow: 2px 0 8px 0 rgb(29 35 41 / 10%);
  border: 1px solid #f0f0f0;
  background: #fafafa;
  .ant-menu-light {
    background: #fafafa;
  }
`
const menuItems = [
  { key: '/', icon: <DashboardOutlined />, label: fm('menu.dashboard') },
  {
    key: 'content',
    icon: <CodeSandboxOutlined />,
    label: fm('sub-menu.content'),
    children: [
      { permission: 'can_show_websites', key: '/content/websites', label: fm('menu.websites') },
      { permission: 'can_show_articles', key: '/content/articles', label: fm('menu.articles') },
      { permission: 'can_show_tags', key: '/content/tags', label: fm('menu.tags') },
      { permission: 'can_show_blocks', key: '/content/blocks', label: fm('menu.blocks') },
      { permission: 'can_show_pages', key: '/content/pages', label: fm('menu.pages') },
      { permission: 'can_show_redirects', key: '/content/redirects', label: fm('menu.redirects') }
    ]
  },
  {
    key: 'assortment',
    icon: <CodeSandboxOutlined />,
    label: fm('sub-menu.assortment'),
    children: [
      { key: '/assortment/categories', icon: <GroupOutlined />, label: fm('menu.categories') },
      { key: '/assortment/products', icon: <BarcodeOutlined />, label: fm('menu.products') },
      { key: '/assortment/specifications', icon: <SlidersOutlined />, label: fm('menu.specification') },
      { key: '/assortment/specification-groups', icon: <BorderInnerOutlined />, label: fm('menu.spec-group') }
    ]
  },
  { key: '/customers', icon: <UserOutlined />, label: fm('menu.customers') },
  { key: '/orders', icon: <ShoppingOutlined />, label: fm('menu.orders') },
  { permission: 'can_show_discounts', key: '/discounts', icon: <DisconnectOutlined />, label: fm('menu.discounts') },
  { permission: 'can_show_vouchers', key: '/vouchers', icon: <PercentageOutlined />, label: fm('menu.vouchers') },
  { permission: 'can_show_free_forms', key: '/free_forms', icon: <FormOutlined />, label: fm('menu.free-forms') },
  {
    key: 'settings',
    icon: <SettingOutlined />,
    label: fm('sub-menu.settings'),
    children: [
      { permission: 'can_show_shops', key: '/settings/shops', label: fm('menu.shops') },
      { permission: 'can_show_users', key: '/settings/users', label: fm('menu.users') },
      { permission: 'can_show_roles', key: '/settings/roles', label: fm('menu.roles') }
    ]
  }
]

export const MainSidebar = () => {
  const push = usePush()
  const allow = useAllow()
  const { subMenu, currentMenu, setSubMenu } = useMenu()
  const handleClick = ({ key }) => {
    if (key[0] === '/') {
      push(key)
    }
  }
  const onOpenChange = (openKeys) => {
    setSubMenu(openKeys)
  }
  const items = _.reduce(menuItems, (result, { permission, children, ...item }) => (
    !permission || allow(permission)
      ? [...result, {
          ...item,
          children: children && _.reduce(children, (result, { permission, ...item }) => (
            !permission || allow(permission) ? [...result, item] : result
          ), [])
        }]
      : result
  ), [])

  return (
    <div className={sidebarWrapper}>
      <Layout.Sider width={200}>
        <Menu
          selectedKeys={currentMenu}
          openKeys={subMenu}
          mode="inline"
          style={{ height: '100%', borderRight: 0 }}
          onClick={handleClick}
          onOpenChange={onOpenChange}
          items={items}
        />
      </Layout.Sider>
    </div>
  )
}
