import * as types from '../../constants/actionTypes/vouchersActionTypes'

export const createVoucher = (dispatch, params) => {
  return dispatch.sync({
    type: types.VOUCHERS_CREATE,
    voucher: params
  })
}

export const updateVoucher = (dispatch, params) => {
  return dispatch.sync({
    type: types.VOUCHERS_UPDATE,
    voucher: params
  })
}

export const deleteVoucher = (dispatch, id, push) => {
  push('/vouchers')
  dispatch.sync({
    type: types.VOUCHERS_DELETE,
    voucher: { id }
  })
}

export const selectVoucher = (dispatch, id) => {
  dispatch.local({
    type: types.VOUCHER_SELECT,
    id
  })
}

export const resetVoucher = (dispatch, id) => {
  dispatch.local({
    type: types.VOUCHER_FORM_RESET,
    id
  })
}

export const changeVouchersTableFilters = (dispatch, filterType, filterValue) => {
  dispatch.local({
    type: types.VOUCHERS_CHANGE_TABLE_FILTER,
    filterType,
    filterValue
  })
}
