import React from 'react'
import { PageHeader } from 'antd'
import { useBreadcrumb } from '../helpers/hooks/useBreadcrumb'

export const DashboardScreen = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)

  return (
    <PageHeader breadcrumb={breadcrumb}>
    </PageHeader>
  )
}
