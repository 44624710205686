import React, { useCallback } from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import { usePush } from '../../../data/actions/routes'
import { useAllow } from '../../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { RedirectsTableHeader } from './RedirectsTableHeader'

const columns = [
  {
    title: fm('table.pattern'),
    key: 'pattern',
    dataIndex: 'pattern'
  },
  {
    title: fm('table.location'),
    key: 'location',
    dataIndex: 'location'
  }
]

export const RedirectsTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const dispatch = useDispatch()
  const { entries } = useSelector((state) => state.redirects)
  const handleClickAdd = () => {
    push('/content/redirects/new')
  }
  const handleClickRow = useCallback(
    (recordId) => {
      push(`/content/redirects/${recordId}`)
    },
    [push, dispatch]
  )
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('redirects.title')}
      extra={
        allow('can_create_redirect') && (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size={'small'}>
        <RedirectsTableHeader />
      </Card>
      <Card size={'small'}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={entries}
          columns={columns}
          size='small'
          loading={isLoading}
          rowKey={'id'}
        />
      </Card>
    </PageHeader>
  )
}
