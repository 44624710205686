import React, { useCallback, useEffect, useState } from 'react'
import { Card, Row, Col, Form, PageHeader, notification } from 'antd'
import { css } from 'linaria'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createTag,
  deleteTag,
  resetTag,
  selectTag,
  updateTag
} from '../../../data/actions/tags'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../../helpers/form'
import { TranslationTabs } from '../../Shared/Translations/TranslationTabs'
import { TranslationFields } from './TranslationFields'
import { emptyTranslation } from '../../../data/reducers/tagsReducer'

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const TagForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.tags)
  const system = entry ? entry.system : false
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/content/tags')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetTag(dispatch)
        handleBack()
      } else {
        form.setFieldsValue(entry)
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, handleBack, isLoading, formValues])
  const onFinish = useCallback(async() => {
    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values }
        await createTag(dispatch, createParams)
        push(`/content/tags/${createParams.id}`)
        notification.success({ message: 'Tag successfully created' })
      } else {
        const updateParams = { id, ...values }
        await updateTag(dispatch, updateParams)
        notification.success({ message: 'Tag successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [newRecord, id, dispatch, entry, form, push])
  const deleteRecord = useCallback(() => {
    deleteTag(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectTag(dispatch, id)
    } else if (newRecord) {
      resetTag(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`tag.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Row gutter={[0, 16]}>
            <Col xs={24}>
              <Form
                form={form}
                layout={'vertical'}
                className={formClass}
                onKeyDown={onKeyDownForm}
                onFinish={onFinish}
                initialValues={{ countryIds: [] }}
              >
                <Row gutter={16}>
                  <Col span={16}>
                    <Card size={'small'} loading={isLoading}>
                      <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                        {({ namespace, translation }) =>
                          <TranslationFields
                            namespace={namespace}
                            locale={translation.locale}
                          />
                        }
                      </TranslationTabs>
                    </Card>
                  </Col>
                </Row>
                {!system && (
                  <FormActionBox
                    isLoading={isLoading}
                    newRecord={newRecord}
                    onDelete={deleteRecord}
                  />
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  )
}
