import React, { useEffect, useState } from 'react'
import { Table, Card, Row, Col, List } from 'antd'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import { MenuOutlined } from '@ant-design/icons'
import { arrayMoveImmutable } from 'array-move'
import { useSelector } from 'react-redux'
import { BlockItem } from './BlockItem'
import { RemoveButton } from './RemoveButton'
import { fm } from '../../../lang'
import { Link } from 'react-router-dom'

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

const SortableItem = SortableElement((props) => <tr {...props} />)
const SortableBody = SortableContainer((props) => <tbody {...props} />)

export const PageBlockForm = ({
  updateBlocks,
  components,
  addBlock,
  removeBlock,
  templateId
}) => {
  const blocks = useSelector((state) => state.blocks.entries)
  const [dataSource, setDataSource] = useState(components)
  const availableTemplates = useSelector((state) => state.templates.entries)
  const [listBlocks, setListBlocks] = useState(blocks)
  const updateListBlocks = (id) => {
    const currentTemplate = availableTemplates.find((el) => el.id === id)
    if (currentTemplate) {
      let newListBlocks = []
      blocks.forEach((block) => {
        if (
          !block.system ||
          (block.system &&
            (block.cmsShowRules.includes('all') ||
              block.cmsShowRules.includes(currentTemplate.key)))
        ) {
          newListBlocks = newListBlocks.concat(block)
        }
      })
      setListBlocks(newListBlocks)
    }
  }
  useEffect(() => {
    updateListBlocks(templateId)
  }, [templateId])
  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Name',
      dataIndex: 'blockableId',
      className: 'drag-visible',
      render: (blockableId) => {
        const block = blocks.find((el) => el.id === blockableId)
        if (block) {
          const blockUrl = `/content/blocks/${block.id}`
          return <Link to={blockUrl} className={'ant-typography'}>{block.name}</Link>
        } else {
          return fm('pages.table.block-not-found')
        }
      }
    },
    {
      title: 'Kind',
      width: 100,
      className: 'drag-hidden',
      render: ({ blockableId }) => {
        const block = blocks.find((el) => el.id === blockableId)
        return block ? block.kind : '—'
      }
    },
    {
      title: 'Actions',
      width: 100,
      className: 'drag-hidden',
      render: (record) => {
        return <RemoveButton id={record.id} removeBlock={removeBlock} />
      }
    }
  ]
  useEffect(() => {
    setDataSource(components)
  }, [components])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource, oldIndex, newIndex)
        .filter((el) => !!el)
        .map((el, index) => {
          return { ...el, index }
        })
      setDataSource(newData)
      updateBlocks(newData)
    }
  }
  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.id === restProps['data-row-key']
    )
    return <SortableItem index={index} {...restProps} />
  }

  return (
    <Card size={'small'}>
      <Row gutter={[16, 16]}>
        <Col xs={16}>
          <Table
            size={'small'}
            pagination={false}
            dataSource={dataSource}
            columns={columns}
            rowKey="id"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: DraggableBodyRow
              }
            }}
          />
        </Col>
        <Col xs={8}>
          <List
            size="small"
            header={<div>Select block</div>}
            bordered
            rowKey="id"
            dataSource={listBlocks}
            renderItem={(item) => <BlockItem item={item} addBlock={addBlock} />}
          />
        </Col>
      </Row>
    </Card>
  )
}
