import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'
import { PrivateRoute } from '../PrivateRoute'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { SpecificationGroupFormScreen } from '../../screens/SpecificationGroups/SpecificationGroupFormScreen'
import { SpecificationGroupsScreen } from '../../screens/SpecificationGroups/SpecificationGroupsScreen'

export const SpecificationGroupsRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['specification_groups'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_specification')}
        path="/assortment/specification-groups/new"
        exact
        isAuthenticated={isAuthenticated}
        component={SpecificationGroupFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_specification')}
        path="/assortment/specification-groups/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={SpecificationGroupFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_specifications')}
        path="/assortment/specification-groups"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={SpecificationGroupsScreen}
      />
    </Switch>
  )
}
