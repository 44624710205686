import * as types from '../../constants/actionTypes/categoriesActionTypes'

export const createCategory = (dispatch, params) => {
  return dispatch.sync({
    type: types.CATEGORIES_CREATE,
    category: params
  })
}

export const updateCategory = (dispatch, params) => {
  return dispatch.sync({
    type: types.CATEGORIES_UPDATE,
    category: params
  })
}

export const deleteCategory = (dispatch, id, push) => {
  push('/assortment/categories')
  dispatch.sync({
    type: types.CATEGORIES_DELETE,
    category: { id }
  })
}

export const selectCategory = (dispatch, id) => {
  dispatch.local({
    type: types.CATEGORY_SELECT,
    id
  })
}

export const resetCategory = (dispatch, id) => {
  dispatch.local({
    type: types.CATEGORY_FORM_RESET,
    id
  })
}
