import * as types from '../../constants/actionTypes/vouchersActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyVoucher = {
  id: null,
  code: '',
  name: '',
  discountType: 'fixed',
  shopIds: [],
  productIds: []
}

const emptyTableFilters = {
  term: null
}

const defaultState = {
  entry: { ...emptyVoucher, id: uuidv4() },
  entries: [],
  giftVoucherUsages: 0,
  filtersEntries: {
    pagination: { current: 1, pageSize: 10, totalCount: 0, totalPage: 0 }
  },
  tableFilters: emptyTableFilters
}

export default function vouchersReducer(state = defaultState, action) {
  switch (action.type) {
    case types.VOUCHERS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.voucher.id) {
            return { ...action.voucher }
          } else {
            return u
          }
        })
      }
    case types.VOUCHERS_DELETE:
      return {
        ...state,
        entry: { ...emptyVoucher, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.voucher.id)
      }
    case types.VOUCHER_FETCH:
      return { ...state, entry: action.data }
    case types.VOUCHERS_CREATE:
      return {
        ...state,
        entries: [{ ...action.voucher }, ...state.entries]
      }
    case types.VOUCHER_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.VOUCHER_FORM_RESET:
      return { ...state, entry: { ...emptyVoucher, id: uuidv4() } }
    case types.VOUCHERS_FETCH:
      return {
        ...state,
        entries: action.data.records,
        giftVoucherUsages: action.data.giftVoucherUsages,
        filtersEntries: {
          ...state.filtersEntries,
          pagination: {
            ...state.filtersEntries.pagination,
            totalCount: action.data.totalCount,
            totalPage: action.data.totalPage
          }
        }
      }
    case types.VOUCHERS_CHANGE_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.filterType]: action.filterValue
        }
      }
    default:
      return state
  }
}
