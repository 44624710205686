import React, { useCallback } from 'react'
import { Card, Table, Button, PageHeader } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import { fm } from '../../lang'
import { usePush } from '../../data/actions/routes'
import { useAllow } from '../../helpers/hooks/usePermissions'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'

const columns = [
  {
    title: fm('table.title'),
    key: 'title',
    render: ({ translations }) => (
      translations.find(({ locale }) => locale === 'en').title
    )
  },
  {
    title: fm('table.slug'),
    key: 'slug',
    render: ({ translations }) => (
      translations.find(({ locale }) => locale === 'en').slug
    )
  },
  {
    title: fm('table.name'),
    key: 'name',
    dataIndex: 'name'
  }
]

export const CategoriesTable = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const allow = useAllow()
  const push = usePush()
  const { entries } = useSelector((state) => state.categories)
  const handleClickAdd = () => {
    push('/assortment/categories/new')
  }
  const handleClickRow = useCallback((recordId) => {
    push(`/assortment/categories/${recordId}`)
  }, [push])
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm('categories.title')}
      extra={
        allow('can_create_category') && (
          <Button
            type={'primary'}
            icon={<PlusOutlined />}
            onClick={handleClickAdd}
          >
            {fm('button.add')}
          </Button>
        )
      }
    >
      <Card size={'small'}>
        <Table
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleClickRow(record.id)
            }
          }}
          dataSource={entries}
          columns={columns}
          size="small"
          loading={isLoading}
          rowKey={'id'}
        />
      </Card>
    </PageHeader>
  )
}
