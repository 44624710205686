import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  PageHeader,
  InputNumber,
  Checkbox,
  Tooltip,
  Typography,
  notification
} from 'antd'
import { css } from 'linaria'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../lang'
import {
  createCategory,
  updateCategory,
  deleteCategory,
  resetCategory,
  selectCategory
} from '../../data/actions/categories'
import { useRecordHook } from '../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../Shared/FormActionBox'
import { usePush } from '../../data/actions/routes'
import { showError } from '../../data/actions/ui'
import { useBreadcrumb } from '../../helpers/hooks/useBreadcrumb'
import { onKeyDownForm } from '../../helpers/form'
import { InfoCircleOutlined } from '@ant-design/icons'
import { TranslationTabs } from '../Shared/Translations/TranslationTabs'
import { TranslationFields } from './TranslationFields'
import { emptyTranslation } from '../../data/reducers/categoriesReducer'

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const CategoryForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.categories)
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/assortment/categories')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetCategory(dispatch)
        handleBack()
      } else {
        form.setFieldsValue(entry)
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, dispatch, form, handleBack, isLoading, formValues])
  const onFinish = useCallback(async() => {
    const values = form.getFieldsValue(true)

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values }
        await createCategory(dispatch, createParams)
        push(`/assortment/categories${createParams.id}`)
        notification.success({ message: 'Category successfully created' })
      } else {
        const updateParams = { id, ...values }
        await updateCategory(dispatch, updateParams)
        notification.success({ message: 'Category successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [newRecord, id, dispatch, entry, form, push])
  const deleteRecord = useCallback(() => {
    deleteCategory(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectCategory(dispatch, id)
    } else if (newRecord) {
      resetCategory(dispatch)
    }
  }, [isLoading, dispatch, id, newRecord])
  const cardTitle = newRecord ? 'new' : 'edit'

  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`category.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Form
        form={form}
        layout={'vertical'}
        className={formClass}
        onKeyDown={onKeyDownForm}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Card size={'small'} loading={isLoading}>
              <Form.Item
                name="name"
                label={fm('label.name')}
                rules={[
                  {
                    required: true,
                    message: 'Please input name'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="trial" label={'Trial(in days)'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name="warranty" label={'Warranty(in months)'}>
                <InputNumber />
              </Form.Item>
              <Form.Item name={'liveStock'} valuePropName={'checked'}>
                <Checkbox>
                  Live stock{' '}
                  <Tooltip
                    title={
                      'Hide the category from the main menu and catalogue depending on product availability.'
                    }
                    color={'blue'}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </Checkbox>
              </Form.Item>
              <Form.Item
                name="googleProductCategory"
                label={fm('label.google-product-category')}
              >
                <Input />
              </Form.Item>
              <Typography.Paragraph>
                <a href="https://support.google.com/merchants/answer/6324436" target="_blank" rel="noreferrer">
                  Download full taxonomy
                </a>
              </Typography.Paragraph>
            </Card>
          </Col>
          <Col span={16}>
            <Card size={'small'} loading={isLoading}>
              <TranslationTabs form={form} emptyTranslation={emptyTranslation}>
                {({ namespace, translation }) =>
                  <TranslationFields
                    namespace={namespace}
                    locale={translation.locale}
                  />
                }
              </TranslationTabs>
            </Card>
          </Col>
        </Row>
        <FormActionBox
          isLoading={isLoading}
          newRecord={newRecord}
          onDelete={deleteRecord}
        />
      </Form>
    </PageHeader>
  )
}
