import React, { useCallback, useEffect, useState } from 'react'
import {
  Card, Row, Col, Form, Input, Select, PageHeader,
  Checkbox, InputNumber, Typography, notification
} from 'antd'
import { css } from 'linaria'
import { useDispatch, useSelector } from 'react-redux'

import { fm } from '../../../lang'
import {
  createShop,
  deleteShop,
  resetShop,
  selectShop,
  updateShop
} from '../../../data/actions/shops'
import { useRecordHook } from '../../../helpers/hooks/useRecordHook'
import { FormActionBox } from '../../Shared/FormActionBox'
import { usePush } from '../../../data/actions/routes'
import { showError } from '../../../data/actions/ui'
import { useBreadcrumb } from '../../../helpers/hooks/useBreadcrumb'
import currencyList from '../../../constants/currencyList'
import { onKeyDownForm } from '../../../helpers/form'
import { useSubscription } from '@logux/redux'
import { TransferCountries } from './ShopForm/TransferCountries'
import { ProductSelect } from '../../Shared/ProductSelect'

const { Option } = Select

const formWrapper = css`
  .ant-card-body {
    padding: 16px;
  }
`

const formClass = css`
  .ant-form-item {
    margin-bottom: 8px;
  }
  .ant-form-item-label {
    padding-bottom: 2px;
  }
`

const initialFormValues = { values: {}, errors: [] }

export const ShopForm = ({ isLoading, path }) => {
  const breadcrumb = useBreadcrumb(path)
  const push = usePush()
  const { newRecord, params } = useRecordHook()
  const { id } = params
  const { entry } = useSelector((state) => state.shops)
  const availableCountries = useSelector((state) => state.countries.entries)
  const isLoadingCountries = useSubscription(['countries'])
  const system = entry ? entry.system : false
  const dispatch = useDispatch()
  const [formValues, setFormValues] = useState(initialFormValues)
  const [form] = Form.useForm()
  const handleBack = useCallback(() => {
    push('/settings/shops')
  }, [push])
  useEffect(() => {
    if (!isLoading) {
      if (entry === undefined) {
        showError(dispatch, 'record-not-found')
        resetShop(dispatch)
        handleBack()
      } else {
        form.setFieldsValue(entry)
        form.setFieldsValue(formValues.values)
        form.setFields(formValues.errors)
      }
    }
  }, [entry, handleBack, resetShop])
  const onFinish = useCallback(async() => {
    const values = form.getFieldsValue(true)
    const giftVoucherProductIds = values.giftVoucherProductIds.flatMap((id) => {
      const ids = id.split(',')
      return ids.length > 1 ? ids.splice(1) : ids
    })

    try {
      setFormValues({ values, errors: formValues.errors.map(({ name }) => ({ name, errors: [] })) })

      if (newRecord) {
        const createParams = { ...entry, ...values, giftVoucherProductIds }
        await createShop(dispatch, createParams)
        push(`/settings/shops/${createParams.id}`)
        notification.success({ message: 'Marketplace successfully created' })
      } else {
        const updateParams = { id, ...values, giftVoucherProductIds }
        await updateShop(dispatch, updateParams)
        notification.success({ message: 'Marketplace successfully updated' })
      }
    } catch (e) {
      const errors = e.action.reason.map(({ path, message }) => (
        {
          name: path,
          errors: [message]
        }
      ))

      setFormValues({ values, errors })
    }
  }, [newRecord, id, dispatch, entry, form, push])
  const deleteRecord = useCallback(() => {
    deleteShop(dispatch, id, push)
  }, [id, push, dispatch])
  useEffect(() => {
    if (!isLoading && !newRecord) {
      selectShop(dispatch, id)
    } else if (newRecord) {
      resetShop(dispatch)
    }
  }, [isLoading, dispatch, selectShop, resetShop])
  const cardTitle = newRecord ? 'new' : 'edit'
  const updateCountries = (values) => {
    form.setFieldsValue({ countryIds: values })
  }
  return (
    <PageHeader
      breadcrumb={breadcrumb}
      title={fm(`shop.form.${cardTitle}`)}
      onBack={handleBack}
    >
      <Row>
        <Col xs={24}>
          <Row gutter={[0, 16]}>
            <Col xs={24}>
              <Form
                form={form}
                layout={'vertical'}
                className={formClass}
                onKeyDown={onKeyDownForm}
                onFinish={onFinish}
                initialValues={{ countryIds: [] }}
              >
                <Row gutter={16}>
                  <Col xs={8}>
                    <Card
                      size={'small'}
                      className={formWrapper}
                      loading={isLoading}
                    >
                      <Form.Item
                        name="name"
                        label={fm('label.name')}
                        rules={[
                          {
                            required: true,
                            message: 'Please input name'
                          }
                        ]}
                      >
                        <Input disabled={system} readOnly={system} />
                      </Form.Item>
                      <Form.Item
                        name="currency"
                        label={fm('label.currency')}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Please select currency'
                          }
                        ]}
                      >
                        <Select placeholder="Please select a currency">
                          {currencyList.map((l) => (
                            <Option key={l.value} value={l.value}>
                              {l.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="trademarkSymbol"
                        label={'Trademark symbol'}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                          name="code"
                          label={fm('label.code')}
                          rules={[
                            {
                              required: true,
                              max: 2,
                              min: 2,
                              message: 'Code must be 2 characters.'
                            }
                          ]}
                      >
                        <Input type="text" maxLength="2"/>
                      </Form.Item>
                      <Form.Item
                        name="orderNumberCountryCode"
                        valuePropName="checked"
                      >
                        <Checkbox>Use country code in order numbers for this shop</Checkbox>
                      </Form.Item>
                      <Form.Item
                        name="klarnaMinTotal"
                        label={fm('label.klarna-min-total')}
                      >
                        <InputNumber
                          min={0}
                          step={0.01}
                          style={{ width: 200 }}
                          precision={2}
                          stringMode
                        />
                      </Form.Item>
                      <Form.Item
                        name="stripeCountryId"
                        label={fm('label.stripe-country')}
                        hasFeedback
                      >
                        <Select placeholder="Please select Stripe country">
                          {availableCountries.map((country) => (
                            <Select.Option
                              key={country.id}
                              value={country.id}
                            >
                              {country.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Card>
                    <Card
                      size={'small'}
                      className={formWrapper}
                      loading={isLoading}
                    >
                      <Form.Item
                        name="giftVoucherPercentage"
                        label={fm('label.gift-voucher-percentage')}
                      >
                        <InputNumber
                          min={1}
                          max={99}
                          style={{ width: 200 }}
                          stringMode
                        />
                      </Form.Item>
                      <Typography.Paragraph type="secondary">
                        If left empty - order gift vouchers will not be generated for the shop orders.
                      </Typography.Paragraph>
                      <Form.Item
                        name="giftVoucherProductIds"
                        label={fm('label.gift-voucher-product-ids')}
                      >
                        <ProductSelect />
                      </Form.Item>
                      <Typography.Paragraph type="secondary">
                        If left empty - voucher will be applicable to all the products.
                      </Typography.Paragraph>
                    </Card>
                  </Col>
                  <Col xs={16}>
                    <Card
                      size={'small'}
                      title={fm('title.delivery-countries')}
                      loading={isLoading || isLoadingCountries}
                    >
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.countryIds !== curValues.countryIds
                        }
                      >
                        {(f) => {
                          return (
                            <TransferCountries
                              countries={f.getFieldValue('countryIds')}
                              updateCountries={updateCountries}
                            />
                          )
                        }}
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>
                {!system && (
                  <FormActionBox
                    isLoading={isLoading}
                    newRecord={newRecord}
                    onDelete={deleteRecord}
                  />
                )}
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  )
}
