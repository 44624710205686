import React from 'react'
import { Col, Input, Row, Select, Typography, Switch } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { changeProductsTableFilters } from '../../data/actions/products'

const { Text, Title } = Typography

export const ProductsTableFilter = ({ updatePage }) => {
  const categories = useSelector((state) => state.categories.entries)
  const currentCategories = useSelector(
    (state) => state.products.tableFilters.categories
  )
  const { term, lowStock } = useSelector((state) => state.products.tableFilters)
  const dispatch = useDispatch()
  const selectCategory = (value) => {
    updatePage(1)
    changeProductsTableFilters(dispatch, 'categories', value)
  }
  const clearCategory = () => {
    updatePage(1)
    changeProductsTableFilters(dispatch, 'categories', [])
  }
  const onTermChange = (e) => {
    updatePage(1)
    changeProductsTableFilters(dispatch, 'term', e.target.value)
  }
  const onLowStockChange = (checked) => {
    updatePage(1)
    changeProductsTableFilters(dispatch, 'lowStock', checked)
  }
  return (
    <Row gutter={[16, 4]}>
      <Col xs={24}>
        <Title level={5}>Filters</Title>
      </Col>
      <Col xs={6}>
        <Row>
          <Text>Name</Text>
          <Input allowClear onChange={onTermChange} defaultValue={term} />
        </Row>
      </Col>
      <Col xs={6}>
        <Row>
          <Text>Category</Text>
          <Select
            mode="multiple"
            allowClear
            onChange={selectCategory}
            style={{ width: '100%' }}
            value={currentCategories}
            placeholder="Filter by category"
            onClear={clearCategory}
          >
            {categories.map((el) => (
              <Select.Option key={el.id}>
                {el.translations.find(({ locale }) => locale === 'en').title}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </Col>
      <Col xs={6}>
        <Row>
          <Switch checkedChildren="Low stock" unCheckedChildren="All" checked={lowStock} onChange={onLowStockChange} />
        </Row>
      </Col>
    </Row>
  )
}
