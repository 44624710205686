import * as types from '../../constants/actionTypes/specificationGroupsActionTypes'
import { v4 as uuidv4 } from 'uuid'

export const emptyTranslation = {
  locale: null,
  title: ''
}

const emptyEntry = {
  id: null,
  name: '',
  visualType: 'column',
  translations: [{ ...emptyTranslation, locale: 'en' }]
}

const defaultState = {
  entry: { ...emptyEntry, id: uuidv4() },
  entries: [],
  filtersEntries: {
    pagination: { current: 1, pageSize: 10, totalCount: 0, totalPage: 0 }
  }
}

export default function specificationGroupsReducer(state = defaultState, action) {
  switch (action.type) {
    case types.SPECIFICATION_GROUPS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.record.id) {
            return { ...action.record }
          } else {
            return u
          }
        })
      }
    case types.SPECIFICATION_GROUPS_UPDATE_POSITION:
      return {
        ...state,
        entries: action.records
      }
    case types.SPECIFICATION_GROUPS_DELETE:
      return {
        ...state,
        entry: { ...emptyEntry, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.record.id)
      }
    case types.SPECIFICATION_GROUP_FETCH:
      return { ...state, entry: action.data }
    case types.SPECIFICATION_GROUPS_CREATE:
      return {
        ...state,
        entries: [{ ...action.record }, ...state.entries]
      }
    case types.SPECIFICATION_GROUP_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.SPECIFICATION_GROUP_FORM_RESET:
      return { ...state, entry: { ...emptyEntry, id: uuidv4() } }
    case types.SPECIFICATION_GROUPS_FETCH:
      return {
        ...state,
        entries: action.data.records,
        filtersEntries: {
          ...state.filtersEntries,
          pagination: {
            ...state.filtersEntries.pagination,
            totalCount: action.data.totalCount,
            totalPage: action.data.totalPage
          }
        }
      }
    default:
      return state
  }
}
