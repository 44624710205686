import React from 'react'
import { Transfer } from 'antd'
import { useSelector } from 'react-redux'

export const TransferCountries = ({ countries, updateCountries }) => {
  const filterOption = (inputValue, option) =>
    option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  const countriesList = useSelector((state) => state.countries.entries)
  const dataSource = countriesList.map((el) => ({
    key: el.id,
    name: el.name,
    chosen: false
  }))
  const handleChange = (targetKeys) => {
    updateCountries(targetKeys)
  }
  return (
    <Transfer
      listStyle={{
        width: '100%',
        height: 500
      }}
      dataSource={dataSource}
      showSearch
      filterOption={filterOption}
      targetKeys={countries}
      onChange={handleChange}
      render={(item) => item.name}
    />
  )
}
