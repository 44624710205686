import React from 'react'
import { Button, Card, Col, Row } from 'antd'
import { fm } from '../../../lang'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { AddressText } from '../../Shared/AdressText'
import { selectStatus, DELIVERY_STATUSES } from '../OrdersTable'
export const BasicInfo = ({ loading }) => {
  const entry = useSelector((state) => state.orders.entry)
  const {
    number,
    customer,
    shopId,
    deliveryAddress,
    paymentAddress,
    paymentMethod,
    createdAt,
    paymentStatus,
    lastDeliveryStatus,
    voucher
  } = entry
  const shops = useSelector((state) => state.shops.entries)
  const countries = useSelector((state) => state.countries.entries)
  const shop = shops.find((el) => el.id === shopId)

  const formatAddress = (address) => {
    const country =
      address && address !== undefined
        ? countries.find((el) => el.id === address.countryId)
        : ''
    const r = []
    if (address.fullName) {
      r.push(address.fullName)
    }
    if (address.address) {
      r.push(address.address)
    }
    if (address.address2) {
      r.push(address.address2)
    }
    if (address.city) {
      r.push(address.city)
    }
    if (address.stateName) {
      r.push(address.stateName)
    }
    if (country) {
      r.push(country.name)
    }
    if (address.postcode) {
      r.push(address.postcode)
    }
    return r.join(',\n')
  }
  const copyAddressToBuffer = () => {
    navigator.clipboard.writeText(formatAddress(deliveryAddress))
  }
  const copyPaymentAddressToBuffer = () => {
    navigator.clipboard.writeText(formatAddress(paymentAddress))
  }
  return (
    <>
      <Col xs={8}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card
              size={'small'}
              loading={loading}
              title={fm('title.order-info')}
            >
              <table>
                <tbody>
                  <tr>
                    <td valign={'top'}>
                      <b>{fm('label.number')}</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>{number}</td>
                  </tr>
                  <tr>
                    <td valign={'top'}>
                      <b>Date</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>{createdAt}</td>
                  </tr>
                  <tr>
                    <td valign={'top'}>
                      <b>{fm('label.shop')}</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}> {shop ? shop.name : '—'}</td>
                  </tr>
                  <tr>
                    <td valign={'top'}>
                      <b>{fm('label.customer')}</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {customer && customer.email}
                      <br />
                      {customer && customer.fullName}
                    </td>
                  </tr>
                  <tr>
                    <td valign={'top'}>
                      <b>Delivery status</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {lastDeliveryStatus
                        ? `${DELIVERY_STATUSES[lastDeliveryStatus.identifier]} at ${moment(lastDeliveryStatus.occurred_at).format('LLL')}`
                        : '—'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
          <Col xs={24}>
            <Card size={'small'} loading={loading} title={'Payment details'}>
              <table>
                <tbody>
                  <tr>
                    <td valign={'top'}>
                      <b>Method</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>{paymentMethod}</td>
                  </tr>
                  <tr>
                    <td valign={'top'}>
                      <b>Status</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {selectStatus(paymentStatus, entry)}
                    </td>
                  </tr>
                  <tr>
                    <td valign={'top'}>
                      <b>Voucher</b>
                    </td>
                    <td style={{ paddingLeft: 16 }}>
                      {voucher && voucher.cents > 0
                        ? `${voucher.text} • ${voucher.value}`
                        : '—'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>
      </Col>
      <Col xs={8}>
        <Card
          size={'small'}
          title={fm('order.delivery-address')}
          loading={loading}
          extra={
            <Col xs={24}>
              <Button type={'primary'} onClick={copyAddressToBuffer}>
                Copy
              </Button>
            </Col>
          }
        >
          <Row>
            <Col xs={24}>
              <AddressText address={deliveryAddress} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={8}>
        <Card
          size={'small'}
          title={fm('order.payment-address')}
          loading={loading}
          extra={
            <Col xs={24}>
              <Button type={'primary'} onClick={copyPaymentAddressToBuffer}>
                Copy
              </Button>
            </Col>
          }
        >
          <AddressText address={paymentAddress} />
        </Card>
      </Col>
    </>
  )
}
