import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Tabs, Row, Col } from 'antd'
import { css } from 'linaria'

import { AddTranslationModal } from '../../Shared/Translations/AddTranslationModal'
import { ProductSpecFields } from './ProductSpecFields'
import { emptySpecTranslation } from '../../../data/reducers/productsReducer'

const tabsClass = css`
  .ant-form-item-label {
    position: absolute;
    left: -250px;
  }
`

export const ProductSpecLocales = ({ entryId, form, isLoading }) => {
  const websites = useSelector((state) => state.websites.entries)
  const websiteLocales = [...new Set(websites.map(({ locale }) => locale))]

  const locales = [...new Set(['en', ...form.getFieldValue('specs').flatMap(
    ({ translations }) => translations.map(({ locale }) => locale)
  )])]
  const unusedLocales = websiteLocales.filter(locale => !locales.includes(locale))

  const [modalOpen, setModalOpen] = useState(false)
  const onModalCancel = useCallback(() => setModalOpen(false), [setModalOpen])
  const onModalAdd = useCallback(({ locale }) => {
    form.setFieldsValue({
      specs: form.getFieldValue('specs').map((spec) => ({
        ...spec, translations: [...spec.translations, { ...emptySpecTranslation, locale }]
      }))
    })
    setModalOpen(false)
  }, [locales, setModalOpen])
  const onEdit = useCallback((targetKey, action) => {
    switch (action) {
      case 'add':
        setModalOpen(true)
        break
      case 'remove':
        form.setFieldsValue({
          specs: form.getFieldValue('specs').map((spec) => ({
            ...spec, translations: spec.translations.filter(({ locale }) => locale !== targetKey)
          }))
        })
        break
      default:
    }
  }, [locales, setModalOpen])

  return (
    <Row>
      <Col style={{ marginLeft: '250px', width: '100%' }}>
        <AddTranslationModal
          open={modalOpen}
          onCancel={onModalCancel}
          onAdd={onModalAdd}
          usedLocales={locales}
        />
        <Tabs
          type="editable-card"
          hideAdd={unusedLocales.length === 0}
          onEdit={onEdit}
          className={tabsClass}
          items={
            locales.map(locale => {
              const tabWebsites = websites.filter(website => website.locale === locale)
              const websiteNames = tabWebsites.map(({ name }) => name)
              const tabTitle = websiteNames.length > 0 ? websiteNames.join(', ') : '<no website>'

              return {
                key: locale,
                label: `${tabTitle} (${locale})`,
                closable: !tabWebsites.find(website => website.prefix === ''),
                children: <ProductSpecFields locale={locale} locales={locales} form={form} />
              }
            })
          }
        />
      </Col>
    </Row>
  )
}
