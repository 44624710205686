import React from 'react'
import { Row, Col, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { changeVouchersTableFilters } from '../../data/actions/vouchers'

export const VouchersTableHeader = () => {
  const dispatch = useDispatch()
  const filters = useSelector((state) => state.vouchers.tableFilters)
  const handleTermChange = (e) => {
    changeVouchersTableFilters(dispatch, 'term', e.target.value)
  }

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Input allowClear onChange={handleTermChange} defaultValue={filters.term} />
      </Col>
    </Row>
  )
}
