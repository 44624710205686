import React from 'react'
import { Form, Select } from 'antd'
import { useSelector } from 'react-redux'
import { useSubscription } from '@logux/redux'
import { fm } from '../../../lang'

export const CountriesSelect = ({ prefix }) => {
  useSubscription(['countries'])
  const countries = useSelector((state) => state.countries.entries)
  return (
    <Form.Item
      name={prefix ? [prefix, 'countryId'] : 'countryId'}
      label={fm('label.country')}
    >
      <Select
        showSearch
        placeholder="Please select a country"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        notFoundContent={null}
      >
        {countries.map((el) => (
          <Select.Option key={el.id} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
