import React from 'react'
import { useRouteMatch, Link } from 'react-router-dom'
import { HomeOutlined } from '@ant-design/icons'

import breadcrumbNameMap from '../../constants/breadcrumbNameMap'

const defaultRoutes = [
  {
    path: '/',
    breadcrumbName: <HomeOutlined />,
    children: [],
    noneSelect: false
  }
]

const itemRender = (route, params, routes, paths) => {
  const last = routes.indexOf(route) === routes.length - 1
  const url = route.children && route.children.length > 0 ? '' : `/${paths.join('/')}`
  return last
    ? (
        route.breadcrumbName
      )
    : url && !route.noneSelect
      ? (
    <Link to={url} className={'ant-typography'}>
      {route.breadcrumbName}
    </Link>
        )
      : (
          route.breadcrumbName
        )
}

export const useBreadcrumb = (path) => {
  const match = useRouteMatch(path)
  const pathSnippets = match.path.split('/').filter((i) => i)
  const routes = defaultRoutes.concat(
    pathSnippets
      ? pathSnippets.map((_, index) => {
        const pathUrl = `/${pathSnippets.slice(0, index + 1).join('/')}`
        const value = breadcrumbNameMap[pathUrl]
        const url = pathSnippets[index]
        let text = ''
        if (pathSnippets[index].includes(':')) {
          // text = capitalize(params[breadcrumbNameMap[pathUrl].text]);
          // url = text.toLowerCase();
          text = value.text
        } else {
          text = value.text
        }
        const children = value.children ? value.children : []
        return {
          path: url,
          breadcrumbName: text,
          children,
          noneSelect: value.noneSelect
        }
      })
      : []
  )
  return { routes, itemRender }
}
