import { createSelector } from 'reselect'
import _ from 'lodash'
const getBlocks = (state) => state.blocks.entries
const getFilters = (state) => state.blocks.tableFilters
export const getObjectListBlocks = createSelector(getBlocks, (blocks) => {
  const r = {}
  blocks.forEach((p) => {
    r[p.id] = {
      ...p
    }
  })
  return r
})

export const getBlocksTags = createSelector(getBlocks, (blocks) => {
  let r = []
  blocks.forEach((p) => {
    r = r.concat(p.tags)
  })
  return _.uniq(r)
})

export const getFilteredBlocks = createSelector(
  getBlocks,
  getFilters,
  (blocks, filters) => {
    if (blocks.length === 0) return []
    let result = blocks
    if (filters.tags && filters.tags.length > 0) {
      result = result.filter((p) => {
        return p.tags.some((r) => filters.tags.includes(r))
      })
    }
    if (filters.term && filters.term !== '') {
      result = result.filter((p) => p.name.toLowerCase().includes(filters.term.toLowerCase()) ||
        (p.code && p.code.includes(filters.term)) || (p.style && p.style.includes(filters.term)))
    }
    if (filters.kind && filters.kind !== '') {
      result = result.filter((p) => p.kind === filters.kind)
    }
    return result
  }
)
