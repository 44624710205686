import React from 'react'
import { Table, Row, Col, Card } from 'antd'

import { fm } from '../../../lang'
import Text from 'antd/es/typography/Text'

const columns = [
  {
    title: fm('table.name'),
    dataIndex: 'name',
    width: '30%',
    key: 'name'
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    number: true,
    width: '5%',
    key: 'quantity'
  },
  {
    title: fm('table.price'),
    dataIndex: 'unitPrice',
    width: '10%',
    key: 'unitPrice'
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    width: '15%',
    key: 'discount',
    render: (discount, record) => (
      discount && discount.name ? `${discount.name} - ${discount.value}` : '—'
    )
  }
]

export const OrderItemsForm = ({
  items,
  isLoading,
  details,
  giftMessage
}) => {
  return (
    <Card size={'small'} title={'Order items'}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Table
            size="small"
            rowKey="name"
            bordered
            loading={isLoading}
            dataSource={items}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </Col>
        <Col xs={24}>
          <Row>
            <Col xs={24}>
              <Text>
                <b>Comments:</b> {details || '—'}
              </Text>
            </Col>
            <Col xs={24}>
              <Text>
                <b>Gift message:</b> {giftMessage || '—'}
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
