import * as types from '../../constants/actionTypes/tagsActionTypes'
import { v4 as uuidv4 } from 'uuid'

export const emptyTranslation = {
  locale: null,
  name: null,
  slug: null
}

const emptyTag = {
  id: null,
  translations: [{ ...emptyTranslation, locale: 'en' }]
}

const defaultState = {
  entry: { ...emptyTag, id: uuidv4() },
  entries: []
}

export default function tagsReducer(state = defaultState, action) {
  switch (action.type) {
    case types.TAGS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.tag.id) {
            return { ...action.tag }
          } else {
            return u
          }
        })
      }
    case types.TAGS_DELETE:
      return {
        ...state,
        entry: { ...emptyTag, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.tag.id)
      }
    case types.TAG_FETCH:
      return { ...state, entry: action.data }
    case types.TAGS_CREATE:
      return {
        ...state,
        entries: [{ ...action.tag }, ...state.entries]
      }
    case types.TAG_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.TAG_FORM_RESET:
      return { ...state, entry: { ...emptyTag, id: uuidv4() } }
    case types.TAGS_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
