import React, { useCallback } from 'react'
import { Button } from 'antd'
import { fm } from '../../../lang'

export const RemoveButton = ({ id, onRemove, kind }) => {
  const handleClick = useCallback(() => {
    kind ? onRemove(id, kind) : onRemove(id)
  }, [onRemove, id, kind])
  return (
    <Button type="dashed" size={'small'} onClick={handleClick}>
      {fm('button.delete')}
    </Button>
  )
}
