import * as types from '../../constants/actionTypes/shopsActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyShop = {
  id: null,
  name: '',
  countryIds: [],
  trademarkSymbol: '',
  currency: '',
  code: '',
  orderNumberCountryCode: false,
  klarnaMinTotal: 0,
  stripeCountryId: null,
  giftVoucherPercentage: null,
  giftVoucherProductIds: []
}

const defaultState = {
  entry: { ...emptyShop, id: uuidv4() },
  entries: []
}

export default function shopsReducer(state = defaultState, action) {
  switch (action.type) {
    case types.SHOPS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.shop.id) {
            return { ...action.shop }
          } else {
            return u
          }
        })
      }
    case types.SHOPS_DELETE:
      return {
        ...state,
        entry: { ...emptyShop, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.shop.id)
      }
    case types.SHOP_FETCH:
      return { ...state, entry: action.data }
    case types.SHOPS_CREATE:
      return {
        ...state,
        entries: [{ ...action.shop }, ...state.entries]
      }
    case types.SHOP_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.SHOP_FORM_RESET:
      return { ...state, entry: { ...emptyShop, id: uuidv4() } }
    case types.SHOPS_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
