import * as types from '../../constants/actionTypes/rolesActionTypes'
import { v4 as uuidv4 } from 'uuid'

const emptyRole = {
  id: null,
  name: '',
  permissionsKeys: [],
  system: false
}

const defaultState = {
  entry: { ...emptyRole, id: uuidv4() },
  entries: []
}

export default function rolesReducer(state = defaultState, action) {
  switch (action.type) {
    case types.ROLES_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.role.id) {
            return { ...action.role }
          } else {
            return u
          }
        })
      }
    case types.ROLES_DELETE:
      return {
        ...state,
        entry: { ...emptyRole, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.role.id)
      }
    case types.ROLE_FETCH:
      return { ...state, entry: action.data }
    case types.ROLES_CREATE:
      return {
        ...state,
        entries: [{ ...action.role }, ...state.entries]
      }
    case types.ROLE_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.ROLE_FORM_RESET:
      return { ...state, entry: { ...emptyRole, id: uuidv4() } }
    case types.ROLES_FETCH:
      return { ...state, entries: action.data }
    default:
      return state
  }
}
