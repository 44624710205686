import * as types from '../../constants/actionTypes/ordersActionTypes'

export const selectOrder = (dispatch, id) => {
  dispatch.local({
    type: types.ORDER_SELECT,
    id
  })
}

export const resetOrder = (dispatch, id) => {
  dispatch.local({
    type: types.ORDER_FORM_RESET,
    id
  })
}

export const changeOrdersFilters = (dispatch, values) => {
  dispatch.local({
    type: types.ORDERS_CHANGE_FILTERS,
    values
  })
}

export const sendOrderEmail = (dispatch, id, emailType) => {
  dispatch.sync({
    type: types.ORDER_SEND_EMAIL,
    order: { id },
    emailType
  })
}
