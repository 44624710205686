import * as types from '../../constants/actionTypes/productsActionTypes'
import { v4 as uuidv4 } from 'uuid'

export const emptyTranslation = {
  locale: null,
  slug: null,
  name: null,
  seoTitle: null,
  seoKeywords: null,
  seoDescription: null,
  socialTitle: null,
  socialDescription: null,
  editor: {},
  supportEditor: {},
  faqEditor: {},
  shortText: null,
  longText: null,
  feature: null,
  details: null,
  guideMediaId: null
}

export const emptyVariant = {
  id: null,
  sku: null,
  ean: null,
  shopId: null,
  productId: null,
  countryIds: [],
  sageWarehouseId: null,
  sadVat: false,
  colorId: null,
  warehouseQuantity: 0,
  availableQuantity: 0,
  futureQuantity: 0,
  futureDeliveryDate: null,
  mediaGroups: [],
  enabled: true
}

export const emptySpecTranslation = {
  locale: null,
  value: null
}

export const emptySpec = {
  id: null,
  specificationId: null,
  translations: [{ ...emptySpecTranslation, locale: 'en' }]
}

const emptyProduct = {
  id: null,
  name: '',
  quantity: 0,
  weight: 0,
  priceUsd: 0,
  priceEur: 0,
  priceGbp: 0,
  rating: 0,
  reviewCount: 0,
  trial: 0,
  warranty: 0,
  categoryId: null,
  medias: [],
  tags: [],
  mediaGroups: [],
  variants: [],
  specs: [],
  audios: [],
  tagIds: [],
  relatedProductIds: [],
  publishedAt: null,
  translations: [{ ...emptyTranslation, locale: 'en' }],
  properties: {
    vimeoHowToCode: '',
    compareBlockId: null,
    linkedContent: false,
    productLinkedId: null,
    showTrademark: false,
    refurbished: false
  }
}

const emptyFilters = {
  categories: [],
  term: '',
  lowStock: false
}

const defaultState = {
  entry: { ...emptyProduct, id: uuidv4() },
  entries: [],
  tableFilters: emptyFilters
}

export default function productsReducer(state = defaultState, action) {
  switch (action.type) {
    case types.PRODUCTS_CREATE:
      return {
        ...state,
        entries: [{ ...action.product }, ...state.entries]
      }
    case types.PRODUCTS_UPDATE:
      return {
        ...state,
        entries: state.entries.map((u) => {
          if (u.id === action.product.id) {
            return { ...action.product }
          } else {
            return u
          }
        })
      }
    case types.PRODUCTS_DELETE:
      return {
        ...state,
        entry: { ...emptyProduct, id: uuidv4() },
        entries: state.entries.filter((u) => u.id !== action.product.id)
      }
    case types.PRODUCT_FETCH:
      return {
        ...state,
        entry: action.data
      }
    case types.PRODUCT_SELECT:
      return { ...state, entry: state.entries.find((u) => u.id === action.id) }
    case types.PRODUCT_FORM_RESET:
      return { ...state, entry: { ...emptyProduct, id: uuidv4() } }
    case types.PRODUCTS_FETCH:
      return {
        ...state,
        entries: action.data
      }
    case types.PRODUCTS_CHANGE_TABLE_FILTER:
      return {
        ...state,
        tableFilters: {
          ...state.tableFilters,
          [action.filterType]: action.filterValue
        }
      }
    default:
      return state
  }
}
