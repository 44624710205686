import * as types from '../../constants/actionTypes/specificationsActionTypes'

export const createSpecification = (dispatch, params) => {
  return dispatch.sync({
    type: types.SPECS_CREATE,
    record: params
  })
}

export const updateSpecification = (dispatch, params) => {
  return dispatch.sync({
    type: types.SPECS_UPDATE,
    record: params
  })
}

export const updateSpecificationPosition = (dispatch, entries) => {
  dispatch.sync({
    type: types.SPECIFICATION_UPDATE_POSITION,
    records: entries
  })
}

export const deleteSpecification = (dispatch, id, push) => {
  push('/assortment/specifications')
  dispatch.sync({
    type: types.SPECS_DELETE,
    record: { id }
  })
}

export const selectSpecification = (dispatch, id) => {
  dispatch.local({
    type: types.SPECIFICATION_SELECT,
    id
  })
}

export const resetSpecification = (dispatch, id) => {
  dispatch.local({
    type: types.SPECIFICATION_FORM_RESET,
    id
  })
}
