import React from 'react'
import { Switch } from 'react-router-dom'
import { useSubscription } from '@logux/redux'

import { useAllow } from '../../helpers/hooks/usePermissions'
import { PrivateRoute } from '../PrivateRoute'
import { PagesScreen } from '../../screens/Pages/PagesScreen'
import { PageFormScreen } from '../../screens/Pages/PageFormScreen'

export const PagesRoute = ({ isAuthenticated }) => {
  const isLoading = useSubscription(['pages', 'shops', 'templates'])
  const allow = useAllow()
  return (
    <Switch>
      <PrivateRoute
        allow={allow('can_create_page')}
        path="/content/pages/new"
        exact
        isAuthenticated={isAuthenticated}
        component={PageFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_page')}
        path="/content/pages/:id"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={PageFormScreen}
      />
      <PrivateRoute
        allow={allow('can_edit_page')}
        path="/content/pages/:id/blocks"
        exact
        componentProps={{ isLoading }}
        isAuthenticated={isAuthenticated}
        component={PageFormScreen}
      />
      <PrivateRoute
        allow={allow('can_show_pages')}
        path="/content/pages"
        exact
        isAuthenticated={isAuthenticated}
        componentProps={{ isLoading }}
        component={PagesScreen}
      />
    </Switch>
  )
}
