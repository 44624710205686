import React from 'react'
import { Form, Select } from 'antd'
import { useSelector } from 'react-redux'

export const HtmlModalForm = () => {
  const blocks = useSelector((state) => state.blocks.entries).filter(
    (el) => !el.system
  )
  return (
    <Form.Item name="blockId" label={'HTML block'}>
      <Select placeholder="Please select a blocks" allowClear>
        {blocks.map((el) => (
          <Select.Option key={el.id} value={el.id}>
            {el.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
