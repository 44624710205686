import React, { useState } from 'react'
import { Card, Table, Col, Row, Form, Button } from 'antd'
import { fm } from '../../../lang'
import { AddressModal } from './AddressModal'
import { RemoveButton } from '../../Shared/Table/RemoveButton'

export const AddressesTable = ({
  items,
  createAddress,
  deleteAddress,
  updateAddress,
  kind,
  isLoading
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalForm] = Form.useForm()
  const columns = [
    {
      title: fm('table.name'),
      key: 'name',
      render: (record, value, rowIndex) => {
        return (
          <Button type="link" onClick={() => openModal(record)}>
            {fm('table.address.column.name', { number: rowIndex + 1 })}
          </Button>
        )
      }
    },
    {
      title: fm('table.actions'),
      key: 'actions',
      render: (record) => {
        return (
          <RemoveButton id={record.id} onRemove={deleteAddress} kind={kind} />
        )
      }
    }
  ]
  const openModal = (record) => {
    modalForm.setFieldsValue({ ...record, newRecord: false })
    setModalOpen(true)
  }
  const handleCreateAddress = (values) => {
    createAddress(values, kind)
  }
  const handleUpdateAddress = (values) => {
    updateAddress(values, kind)
  }
  return (
    <Card size={'small'} title={fm(`address.form.${kind}`)}>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <AddressModal
            form={modalForm}
            createAddress={handleCreateAddress}
            updateAddress={handleUpdateAddress}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        </Col>
        <Col xs={24}>
          <Table
            loading={isLoading}
            dataSource={items.filter((el) => !el._destroy)}
            columns={columns}
            size="small"
            rowKey={'id'}
            locale={{ emptyText: fm('table.no-address') }}
            pagination={{ position: ['none', 'none'] }}
          />
        </Col>
      </Row>
    </Card>
  )
}
