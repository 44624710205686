import * as types from '../../constants/actionTypes/tagsActionTypes'

export const createTag = (dispatch, params) => {
  return dispatch.sync({
    type: types.TAGS_CREATE,
    tag: params
  })
}

export const updateTag = (dispatch, params) => {
  return dispatch.sync({
    type: types.TAGS_UPDATE,
    tag: params
  })
}

export const deleteTag = (dispatch, id, push) => {
  push('/content/tags')
  dispatch.sync({
    type: types.TAGS_DELETE,
    tag: { id }
  })
}

export const selectTag = (dispatch, id) => {
  dispatch.local({
    type: types.TAG_SELECT,
    id
  })
}

export const resetTag = (dispatch, id) => {
  dispatch.local({
    type: types.TAG_FORM_RESET,
    id
  })
}
