import React from 'react'
import { Typography } from 'antd'
import { fm } from '../../lang'
import { useSelector } from 'react-redux'

const { Text } = Typography
export const AddressText = ({ address }) => {
  const countries = useSelector((state) => state.countries.entries)
  const country =
    address && address !== undefined
      ? countries.find((el) => el.id === address.countryId)
      : ''
  return address &&
    address !== undefined &&
    Object.keys(address).includes('fullName')
    ? (
    <table>
      <tbody>
        <tr>
          <td>
            <b>Recipient</b>
          </td>
          <td style={{ paddingLeft: 16 }}>
            {address.fullName ? address.fullName : '—'}
          </td>
        </tr>
        <tr>
          <td>
            <b>Phone</b>
          </td>
          <td style={{ paddingLeft: 16 }}>
            {address.phoneNumber ? address.phoneNumber : '—'}
          </td>
        </tr>
        <tr>
          <td>
            <b>Address</b>
          </td>
          <td style={{ paddingLeft: 16 }}>
            {address.address ? address.address : '—'}
          </td>
        </tr>
        <tr>
          <td>
            <b>Address 2</b>
          </td>
          <td style={{ paddingLeft: 16 }}>
            {address.address2 ? address.address2 : '—'}
          </td>
        </tr>
        <tr>
          <td>
            <b>City</b>
          </td>
          <td style={{ paddingLeft: 16 }}>{address.city ? address.city : '—'}</td>
        </tr>
        <tr>
          <td>
            <b>State</b>
          </td>
          <td style={{ paddingLeft: 16 }}> {address.stateName}</td>
        </tr>
        <tr>
          <td>
            <b>Country</b>
          </td>
          <td style={{ paddingLeft: 16 }}> {country && country.name}</td>
        </tr>
        <tr>
          <td>
            <b>Postcode</b>
          </td>
          <td style={{ paddingLeft: 16 }}>
            {address.postcode ? address.postcode : '—'}
          </td>
        </tr>
      </tbody>
    </table>
      )
    : (
    <Text>{fm('text.no-address')}</Text>
      )
}
