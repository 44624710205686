import * as types from '../../constants/actionTypes/redirectsActionTypes'

export const createRedirect = (dispatch, params) => {
  return dispatch.sync({
    type: types.REDIRECTS_CREATE,
    redirect: params
  })
}

export const updateRedirect = (dispatch, params) => {
  return dispatch.sync({
    type: types.REDIRECTS_UPDATE,
    redirect: params
  })
}

export const deleteRedirect = (dispatch, id, push) => {
  push('/content/redirects')
  dispatch.sync({
    type: types.REDIRECTS_DELETE,
    redirect: { id }
  })
}

export const selectRedirect = (dispatch, id) => {
  dispatch.local({
    type: types.REDIRECT_SELECT,
    id
  })
}

export const resetRedirect = (dispatch, id) => {
  dispatch.local({
    type: types.REDIRECT_FORM_RESET,
    id
  })
}

export const changeRedirectsFilters = (dispatch, values) => {
  dispatch.local({
    type: types.REDIRECTS_CHANGE_FILTERS,
    values
  })
}
