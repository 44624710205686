import React from 'react'
import { useSelector } from 'react-redux'
import { Form, Input, Select, Button } from 'antd'
import { MinusOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

import { emptySpec, emptySpecTranslation } from '../../../data/reducers/productsReducer'

export const ProductSpecFields = ({ locale, locales, form }) => {
  // const groups = useSelector((state) => state.specificationGroups.entries)
  const specifications = useSelector((state) => state.specifications.entries)
  const specsIndex = _.keyBy(specifications, 'id')
  const specs = form.getFieldValue('specs')
  const missingSpecifications = _.omit(specsIndex, specs.map(({ specificationId }) => specificationId))

  return (
    <>
      <Form.List name={['specs']}>
        {(fields, { add, remove }) => (
          <>
            <Select
              style={{ position: 'absolute', width: '230px', left: '-250px', top: '-56px' }}
              placeholder="Add specification"
              options={Object.values(missingSpecifications).map(({ id, name }) => ({ value: id, label: name }))}
              onSelect={(value) => add({
                ...emptySpec,
                id: uuidv4(),
                specificationId: value,
                translations: locales.map((locale) => ({ ...emptySpecTranslation, locale }))
              })}
              value={null}
            >
            </Select>
            {fields.map((field) => {
              const spec = specs[field.name]
              const localeKey = spec.translations.findIndex(({ locale: specLocale }) => specLocale === locale)
              const placeholder = locale === 'en' ? '' : spec.translations.find(({ locale }) => locale === 'en').value

              return (
                <Form.Item
                  key={field.key}
                  label={
                    <>
                      <Button
                        onClick={() => remove(field.name)}
                        shape="circle"
                        type="dashed"
                        size="small"
                        style={{ marginRight: '10px' }}
                        icon={<MinusOutlined />}
                      ></Button>
                      {specsIndex[spec.specificationId].name}
                    </>
                  }
                  name={[field.name, 'translations', localeKey, 'value']}
                >
                  <Input.TextArea
                    autoSize={true}
                    placeholder={placeholder}
                  />
                </Form.Item>
              )
            })}
          </>
        )}
      </Form.List>
    </>
  )
}
